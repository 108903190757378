import React from 'react'
import TopBar from '../../components/TopBar'
import { Container } from '@mui/system'
import TableRow from '@mui/material/TableRow'
import HomeHeader from '../../components/HomeHeader'
import { Grid, Typography, Box } from '@mui/material'
import './style.scss'
import HomeTopBar from '../../components/HomeTopBar'
import BookingDetails from '../Booking/BookingDetails'
import BookingForm from './BookingForm'
const Home = () => {
   //process.env.REACT_APP_GOOGLE_KEY

   return (
      <div>
         <HomeHeader />
         <HomeTopBar activeMenu='1' />
         <Grid container className=" homeBackground">
            <Grid xs={12} md={12} sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'top' }} >
               <h1 className='heading' >Book Now</h1>
            </Grid>
            <Grid xs={12} md={12} sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }} >
               <BookingForm />
            </Grid>
            <Grid xs={12} md={12} sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'top', height: '3%' }} >
               <h1 className='heading' >BEST LIMOUSINE SERVICE</h1>
            </Grid>
            <Grid xs={12} md={12} sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'top', height: '3%' }} >
               <h1 className='heading' >In</h1>
            </Grid>
            <Grid xs={12} md={12} sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'top' }} >
               <h1 className='heading'>SINGAPORE</h1>
            </Grid>
         </Grid>
      </div>
   )
}

export default Home
