import React, { useState, useRef, useEffect } from 'react'
import './style.scss'
import { IconButton, Typography ,Snackbar, Alert,} from '@mui/material'
import CheckIcon from '@mui/icons-material/Check';
import Divider from '@mui/material/Divider'
import Container from '@mui/material/Container';
import Grid from '@mui/material/Unstable_Grid2';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import {
    Button,
    FormGroup,
    Popover
} from "@mui/material";
import { useLocation } from 'react-router-dom';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import InputIcon from '../../components/InputIcon'
import CreditImg from '../../assets/images/IconsNew/credit-card.png'
import WalletImg from '../../assets/images/IconsNew/wallet.png'
import { useDispatch, useSelector } from 'react-redux';
import CardDetails from './CardDetails';
import { COUPON_VERIFY_FAIL, COUPON_VERIFY_SUCCESS, RESET_ERROR } from '../../Redux/constants/bookingConstants';
import { getUserCreditTerms, newBooking, verifyCoupon } from '../../Redux/actions/bookingActions';
import { toast } from 'react-toastify';
import reactotron from '../../ReactronConfig';
import Box from '@mui/material/Box/Box';
import ErrorIcon from '@mui/icons-material/Error';
import { isArray } from 'lodash';
import BorderBox from '../../components/BorderBox';
import CloseIcon from '@mui/icons-material/Close';


const PaymentDetails = () => {
    const captchaRef = useRef(null)
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch()


    const { user, loading, myTransfer } = useSelector(state => state.auth)
    const { selectedVehicle, orderData, bookingSuccess, couponError, creditTerms, error, couponDetails, couponApplied, bookingType } = useSelector(state => state.booking)
    const { vehicleAvailability } = useSelector(state => state.mytransfer)

    const { markupLists } = useSelector(state => state.markup)

    const [cardPay, setCardPay] = useState(true)
    const [walletPay, setWalletPay] = useState(false)
    const [creditPay, setCreditPay] = useState(false)
    const [coupon, setCouponApplied] = useState(false)
    const [total, setTotal] = useState(null)
    const [mode, setMode] = useState(false)
    const [openC, setOpenC] = React.useState(false);
    

    const schema = yup.object({

    }).required();

    const { register, handleSubmit, control, formState: { errors }, getValues, reset } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            coupon: ''
        }
    });

    useEffect(() => {
        if (user?.role === "corporate") {
            let data = {
                customer_id: user?.id
            }
            dispatch(getUserCreditTerms(data))
        }

    }, [])


    useEffect(() => {
        if (error) {
            toast.error(error);
            dispatch({
                type: RESET_ERROR
            })
        }
    }, [error])



    const { selectedBooking: booking, currentVehicle: data } = useSelector(state => state.booking)

    const [agentMarkup, setAgentMarkUp] = useState(0)
    const [price, setPrice] = useState(0)
    const [tax, setTax] = useState(0)
    const [rate, setRate] = useState(0)
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event, mode) => {
        if (mode === "item") {
            setMode("item")
        }
        else {
            setMode("service")
        }
        setAnchorEl(event.currentTarget);
    };




    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;










    const onSubmit = (data) => {


        //dispatch(updateProfile(formData))
    };

    const enableCardPay = (status) => {
        setCardPay(status)
        setWalletPay(false)
        setCreditPay(false)
    }

    const payWallet = (status) => {
        setCardPay(false)
        setCreditPay(false)
        setWalletPay(status)
    }

    const enableCreditPay = (status) => {
        if(creditTerms){
            if(creditTerms?.unlimited_limit === 1){
                setCardPay(false)
                setWalletPay(false)
                setCreditPay(true);
            }
            else if(parseFloat(creditTerms?.creditlimit)>0){
                let total = 0
                orderData?.map(order => {
                    total += order?.total_price
                })
                if(total < parseFloat(creditTerms?.creditlimit)){
                    setCardPay(false)
                    setWalletPay(false)
                    setCreditPay(true);
                }
                else{
                    setCardPay(true)
                    setWalletPay(false)
                    setCreditPay(false);
                    toast.warning("Insufficient credit balance. Please use another payment method")
                }
            }
            else{
                setCardPay(true)
                setWalletPay(false)
                setCreditPay(false);
                toast.warning("Insufficient credit balance. Please use another payment method")
            }
        }
        else{
            setCardPay(true)
            setWalletPay(false)
            setCreditPay(false);
            toast.warning("Insufficient credit balance. Please use another payment method")
        }
    }

    const confirmBooking = () => {
        let base_rate = 0;
        let totalPrice = 0;
        let agentMarkup = 0;
        let extra = 0;
        let addOn = 0;
        let tax = 0;
        let code = ''
        let toll = 0;
        let adminMarkup = 0;
        let adminDiscount = 0;
        let extraSeat = 0;
        orderData?.map(order => {
            totalPrice += order?.total_price
            base_rate += order?.base_rate;
            agentMarkup += order?.agentMarkup
            adminMarkup += order?.markup;
            extraSeat += order?.extraSeat;
            tax += Math.ceil(order?.tax)
            toll += Math.ceil(order?.toll)
            adminDiscount +=Math.ceil(order?.adminDiscount)
            order?.addonItem?.map(or => {
                extra += or?.price
            })
            order?.addonservices?.map(ser => {
                if (ser.checked) {
                    addOn += Math.ceil(ser.price)
                }
            })
        })



        let couponDiscount = 0;
        if (couponApplied) {
            code = couponDetails?.code
            if (couponDetails?.discount_type === "PERCENTAGE") {
                couponDiscount = Math.ceil(((base_rate + extra + addOn + toll + adminMarkup - adminDiscount) / 100) * Math.ceil(couponDetails?.discount))
            }
            else {
                couponDiscount = Math.ceil(couponDetails?.discount)
            }


            // if (user?.role === "agent" && markupLists?.status === 1) {
            //     if (markupLists?.type.toLowercase() === "percentage") {
            //         let balanceAmount = base_rate + extra + addOn + toll + adminMarkup - couponDiscount ;
            //         agentMarkup = (balanceAmount / 100) * parseFloat(markupLists?.value)
            //     }
            // }

            tax = ((base_rate + extra + addOn + agentMarkup + adminMarkup - couponDiscount - adminDiscount) / 100) * Math.ceil(orderData[0].taxPer)
            totalPrice = Math.ceil(base_rate + extra + addOn + agentMarkup + adminMarkup - couponDiscount + tax - adminDiscount);
        }
        let data = {
            trips: orderData,
            booking_total: totalPrice,
            payment_type: 'wallet',
            usertype: user?.role,
            customer_id: user?.parentDetails ? user?.parentDetails?.id : user?.id ? user?.id : null,
            created_by: user?.id ? user?.id : null,
            currency: orderData[0].currency,
            code,
            customer_type: user?.customer_type,
            couponDiscount: couponDiscount,
            base_total: base_rate,
            extraCharges: extra,
            tax: tax,
            addOn: addOn,
            agentMarkup: agentMarkup,
            toll: toll,
            adminMarkup: adminMarkup,
            bookingType: bookingType,
            adminDiscount,
            customer_stripe_id: null,
            payment_method_id: null,
            extraSeat
        }

        if (bookingType === "PAL") {
            
            dispatch(newBooking(data))
        }
        else {
            let myTransData = {
                customerCountry: orderData[0]?.customerCountry,
                customerEmail: orderData[0]?.customerEmail,
                customerFirstName: orderData[0]?.customerFirstName,
                customerLastName: orderData[0]?.customerLastName,
                customerPhone: orderData[0]?.customerPhone,
                destinationType: vehicleAvailability?.dropoffType,
                key: process.env.REACT_APP_MYTRANSFER_KEY,
                originType: vehicleAvailability?.pickupType,
                sessionId: vehicleAvailability?.sessionId,
                transferId: orderData[0]?.selectedVehicle?.transferId,
                destinationAddress: orderData[0]?.location_to,
                originAddress: orderData[0]?.location_from,
                specialRequirements: orderData[0]?.specialinstructions,
                extras: orderData[0]?.extras,
                arrivalLine: orderData[0]?.flightno,
                arrivalPickUpTime: orderData[0]?.pickupdate,
                arrivalLocator: 2222
            }


            data.mytransfer = myTransData
            dispatch(newBooking(data))

        }


    }

    const confirmCreditBooking = () => {
        let code = ''
        let base_rate = 0;
        let totalPrice = 0;
        let agentMarkup = 0;
        let extra = 0;
        let addOn = 0;
        let tax = 0;
        let toll = 0;
        let adminMarkup = 0;
        let extraSeat = 0;
        orderData?.map(order => {
            totalPrice += order?.total_price
            base_rate += order?.base_rate;
            agentMarkup += order?.agentMarkup
            extraSeat += order?.extraSeat
            adminMarkup += order?.markup;
            tax += Math.ceil(order?.tax)
            toll += Math.ceil(order?.toll)
            order?.addonItem?.map(or => {
                extra += or?.price
            })
            order?.addonservices?.map(ser => {
                if (ser.checked) {
                    addOn += Math.ceil(ser.price)
                }
            })
        })
        let couponDiscount = 0;
        if (couponApplied) {
            code = couponDetails?.code
            if (couponDetails?.discount_type === "PERCENTAGE") {
                couponDiscount = Math.ceil(((base_rate + extra + addOn + adminMarkup + toll + extraSeat) / 100) * Math.ceil(couponDetails?.discount))
            }
            else {
                couponDiscount = Math.ceil(couponDetails?.discount)
            }


            if (user?.role === "agent" && markupLists?.status === 1) {
                if (markupLists?.type.toLowercase() === "percentage") {
                    let balanceAmount = base_rate + extra + addOn + toll + adminMarkup - couponDiscount + extraSeat;
                    agentMarkup = Math.ceil((balanceAmount / 100) * Math.ceil(markupLists?.value))
                }
            }

            tax = ((base_rate + extra + addOn + agentMarkup - couponDiscount + adminMarkup + extraSeat) / 100) * parseFloat(orderData[0].taxPer)
            totalPrice = Math.ceil(base_rate + extra + addOn + agentMarkup - couponDiscount + tax + adminMarkup + extraSeat);
        }
        let data = {
            trips: orderData,
            booking_total: totalPrice,
            payment_type: 'credit',
            usertype: user?.role,
            customer_id: user?.parentDetails ? user?.parentDetails?.id : user?.id ? user?.id : null,
            created_by: user?.id ? user?.id : null,
            currency: orderData[0].currency,
            code,
            customer_type: user?.customer_type,
            couponDiscount: couponDiscount,
            base_total: base_rate,
            extraCharges: extra,
            tax: tax,
            addOn: addOn,
            agentMarkup: agentMarkup,
            toll: toll,
            adminMarkup: adminMarkup,
            bookingType: bookingType,
            customer_stripe_id: null,
            payment_method_id: null,
            extraSeat
        }


        if (bookingType === "PAL") {
            dispatch(newBooking(data))
        }
        else {
            let myTransData = {
                customerCountry: orderData[0]?.customerCountry,
                customerEmail: orderData[0]?.customerEmail,
                customerFirstName: orderData[0]?.customerFirstName,
                customerLastName: orderData[0]?.customerLastName,
                customerPhone: orderData[0]?.customerPhone,
                destinationType: vehicleAvailability?.dropoffType,
                key: process.env.REACT_APP_MYTRANSFER_KEY,
                originType: vehicleAvailability?.pickupType,
                sessionId: vehicleAvailability?.sessionId,
                transferId: orderData[0]?.selectedVehicle?.transferId,
                destinationAddress: orderData[0]?.location_to,
                originAddress: orderData[0]?.location_from,
                specialRequirements: orderData[0]?.specialinstructions,
                extras: orderData[0]?.extras
            }

            data.mytransfer = myTransData
            dispatch(newBooking(data))

        }
    }

    const handleCloseAlert = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenC(false);
    };

    useEffect(() => {
        if (bookingSuccess) {
            //localStorage.removeItem("booking");
            navigate('/done');
            dispatch({
                type: RESET_ERROR
            })
        }
        if(couponApplied){
             setOpenC(true)
        }
        if (couponError) {
            setCouponApplied(false)
            toast.error(couponError)
            dispatch({
                type: RESET_ERROR
            })
            reset()
        }
    }, [bookingSuccess, couponError,couponApplied])



    const verifyToken = () => {
        const token = getValues("token");
        let data;
        if (!user) {
            data = {
                code: token,
                customer_type: 3,
                user_id: null
            }
        }
        else {
            if (user?.role === "corporate") {
                data = {
                    code: token,
                    customer_type: 1,
                    user_id: user?.id
                }
            }
            else if (user?.role === "agent") {
                data = {
                    code: token,
                    customer_type: 2,
                    user_id: user?.id
                }
            }
            else if (user?.role === "customer") {
                data = {
                    code: token,
                    customer_type: 3,
                    user_id: user?.id
                }
            }
        }
        setCouponApplied(true)
        dispatch(verifyCoupon(data))


    }

    const cancelAppliedCoupon = () => {
        dispatch({
            type: COUPON_VERIFY_FAIL
        })
        setCouponApplied(false)

    }

    const renderPricing = () => {


        


        let base_rate = 0;
        let totalPrice = 0;
        let agentMarkup = 0;
        let extra = 0;
        let addOn = 0;
        let tax = 0;
        let toll = 0;
        let adminMarkup = 0;
        let adminDiscount = 0;
        let extraSeat = 0;
        let extraCharges = null
        let services = []
        orderData?.map((order, index) => {
            if (isArray(order?.addonItem) && order?.addonItem?.length > 0) {
                if (extraCharges) {
                    order?.addonItem?.map(add => {
                        let old = extraCharges?.findIndex(ex => ex?.id === add?.id)
                        if (old >= 0) {
                            extraCharges[old].price = Math.ceil(extraCharges[old].price) + Math.ceil(add?.price)
                        }
                        else {
                            extraCharges.push(add)
                        }
                    })

                }
                else {
                    extraCharges = order?.addonItem
                }
            }

            

            if (isArray(order?.addonservices) && order?.addonservices?.length > 0) {

                let addOnServices = [...order?.addonservices]

                addOnServices?.map(add => {
                    if (add?.checked) {
                        let serFound = false;
                        services?.map(ser => {
                            if(ser?.id === add?.id){
                                serFound = true
                                ser.price = ser?.price + Math.ceil(add?.price)
                            }
                        })

                        if(!serFound){
                            services.push({...add})
                        }

                        // let old = services?.findIndex(ex => ex?.id === add?.id)
                        // if (old >= 0) {
                        //     //services[old].price +=  Math.ceil(add?.price)
                        // }
                        // else {
                        //     services.push(add)
                        // }
                    }

                })
            }

            totalPrice += Math.ceil(order?.total_price)
            base_rate += Math.ceil(order?.base_rate);
            adminMarkup += Math.ceil(order?.markup)
            agentMarkup += Math.ceil(order?.agentMarkup)
            adminDiscount +=Math.ceil(order?.adminDiscount)
            extraSeat += Math.ceil(order?.extraSeat)
            tax += Math.ceil(order?.tax)
            toll += Math.ceil(order?.toll)
            order?.addonItem?.map(or => {
                extra += Math.ceil(or?.price)
            })
            order?.addonservices?.map(ser => {
                if (ser.checked) {
                    addOn += Math.ceil(ser.price)
                }
            })
        })
        let couponDiscount = 0;
        if (couponApplied) {
            if (couponDetails?.discount_type === "PERCENTAGE") {
                couponDiscount = Math.ceil(((base_rate + extra + addOn + toll + extraSeat) / 100) * Math.ceil(couponDetails?.discount))
            }
            else {
                couponDiscount = Math.ceil(couponDetails?.discount)
            }


            tax = ((base_rate + adminMarkup + extra + addOn + agentMarkup + toll - couponDiscount - adminDiscount + extraSeat) / 100) * parseFloat(orderData[0].taxPer ? orderData[0].taxPer : myTransfer?.tax)
            totalPrice = Math.ceil(base_rate + adminMarkup + extra + addOn + agentMarkup - couponDiscount + tax + toll - adminDiscount + extraSeat);
        }

        return (
            <Grid sx={{ height: 'auto', flex: 0.3 }}>
                <Snackbar open={openC} autoHideDuration={2000} onClose={handleCloseAlert}>
                <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
                    Coupon Added Successfully
                </Alert>
            </Snackbar>

                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '88%' }}>
                    <Typography style={{
                        fontFamily: 'Nunito',
                        fontWeight: 600,
                        fontSize: 12,
                        padding: 0,
                        paddingRight: 10,
                        flex: 0.5
                    }}>Base Rate</Typography>
                    <Typography style={{
                        fontFamily: 'Nunito',
                        fontSize: 12,
                        fontWeight: 'bold',
                        padding: 0,
                        paddingRight: 10,
                        textAlign: 'right',
                        flex: 0.5
                    }} >S${Math.ceil(base_rate + adminMarkup)}</Typography>
                </div>
                {extraCharges?.map((extra, index) =>
                    <div key={index} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '88%' }}>
                        <Typography style={{
                            fontFamily: 'Nunito',
                            fontWeight: 600,
                            fontSize: 12,
                            padding: 0,
                            paddingRight: 10,
                            flex: 0.5
                        }}>{extra?.name}</Typography>
                        <Typography style={{
                            fontFamily: 'Nunito',
                            fontSize: 12,
                            fontWeight: 'bold',
                            padding: 0,
                            paddingRight: 10,
                            textAlign: 'right',
                            flex: 0.5
                        }} >S${Math.ceil(extra?.price)}</Typography>
                    </div>)}
                {services?.map((extra, index) => <div key={index} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '88%' }}>
                    <Typography style={{
                        fontFamily: 'Nunito',
                        fontWeight: 600,
                        fontSize: 12,
                        padding: 0,
                        paddingRight: 10,
                        flex: 0.5
                    }}>{extra?.name}</Typography>
                    <Typography style={{
                        fontFamily: 'Nunito',
                        fontSize: 12,
                        fontWeight: 'bold',
                        padding: 0,
                        paddingRight: 10,
                        textAlign: 'right',
                        flex: 0.5
                    }} >S${Math.ceil(extra?.price)}
                    </Typography>
                </div>)}
                {/* <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', flex: 0.5 }} >
                        <Typography style={{
                            fontFamily: 'Nunito',
                            fontWeight: 600,
                            fontSize: 12,
                            padding: 0,
                        }}>
                            Extra Charges
                        </Typography>
                        {extraCharges && <IconButton aria-describedby={id} onClick={(e) => handleClick(e, 'item')}>
                            <ErrorIcon style={{ width: 15, height: 15, marginRight: 10 }} />
                        </IconButton>}
                    </div>
                    <Typography style={{
                        fontFamily: 'Nunito',
                        fontSize: 12,
                        fontWeight: 'bold',
                        padding: 0,
                        flex: 0.5,
                    }} >S${extra.toFixed(2)}</Typography>
                </div>
                <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                >
                    {mode === "item" && <div style={{ display: 'flex', flexDirection: 'column' }}>
                        {extraCharges?.map((extra, index) => <div key={index} style={{ display: 'flex', flexDirection: 'row', width: 200, paddingLeft: 10, paddingRight: 10 }}>
                            <div style={{ flex: 0.7 }}>
                                <Typography fontSize={12}>{extra?.name}</Typography>
                            </div>
                            <div style={{ flex: 0.3 }}>
                                <Typography fontSize={12}>S${extra?.price}</Typography>
                            </div>
                        </div>)}
                    </div>}
                    {mode === "service" && <div style={{ display: 'flex', flexDirection: 'column' }}>
                        {services?.map((extra, index) => <div key={index} style={{ display: 'flex', flexDirection: 'row', width: 200, paddingLeft: 10, paddingRight: 10 }}>
                            <div style={{ flex: 0.7 }}>
                                <Typography fontSize={12}>{extra?.name}</Typography>
                            </div>
                            <div style={{ flex: 0.3 }}>
                                <Typography fontSize={12}>S${extra?.price}</Typography>
                            </div>
                        </div>)}
                    </div>}
                </Popover>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'start', alignItems: 'center' }}>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', flex: 0.5 }} >
                        <Typography style={{
                            fontFamily: 'Nunito',
                            fontWeight: 600,
                            fontSize: 12,
                            padding: 0
                        }}>
                            Add on Services
                        </Typography>
                        {services && <IconButton aria-describedby={id} onClick={(e) => handleClick(e, 'service')}>
                            <ErrorIcon style={{ width: 15, height: 15, marginRight: 10 }} />
                        </IconButton>}
                    </div>
                    <Typography style={{
                        fontFamily: 'Nunito',
                        fontSize: 12,
                        fontWeight: 'bold',
                        flex: 0.5
                    }} >S${addOn.toFixed(2)}</Typography>
                </div> */}
                {user && user.role === "agent" && agentMarkup >0 && <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '88%' }}>
                    <Typography style={{
                        fontFamily: 'Nunito',
                        fontWeight: 600,
                        fontSize: 12,
                        paddingRight: 10,
                        flex: 0.5
                    }}>Markup Price</Typography>
                    <Typography style={{
                        fontFamily: 'Nunito',
                        fontSize: 12,
                        fontWeight: 'bold',
                        paddingRight: 10,
                        textAlign: 'right',
                        flex: 0.5
                    }} >S${Math.ceil(agentMarkup)}</Typography>
                </div>}
                {toll != 0 && <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '88%' }}>
                    <Typography style={{
                        fontFamily: 'Nunito',
                        fontSize: 12,
                        fontWeight: 600,
                        paddingRight: 10,
                        flex: 0.5
                    }}>Toll Rate</Typography>
                    <Typography style={{
                        fontFamily: 'Nunito',
                        fontSize: 12,
                        fontWeight: 'bold',
                        textAlign: 'right',
                        paddingRight: 10,
                        flex: 0.5
                    }}  >S${Math.ceil(toll)}</Typography>
                </div>}
                {extraSeat > 0 && <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '88%' }}>
                    <Typography style={{
                        fontFamily: 'Nunito',
                        fontSize: 12,
                        fontWeight: 600,
                        paddingRight: 10,
                        flex: 0.5
                    }}>Extra Seat</Typography>
                    <Typography style={{
                        fontFamily: 'Nunito',
                        fontSize: 12,
                        fontWeight: 'bold',
                        textAlign: 'right',
                        paddingRight: 10,
                        flex: 0.5
                    }}  >S${Math.ceil(extraSeat)}</Typography>
                </div>}
                {couponApplied && <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '88%' }}>
                    <Typography style={{
                        fontFamily: 'Nunito',
                        fontWeight: 600,
                        paddingRight: 10,
                        fontSize: 12,
                        flex: 0.5
                    }}>Coupon Discount</Typography>
                    <Typography style={{
                        fontFamily: 'Nunito',
                        fontSize: 12,
                        fontWeight: 'bold',
                        paddingRight: 10,
                        textAlign: 'right',
                        flex: 0.5
                    }}  >- S${Math.ceil(couponDiscount)}</Typography>
                </div>}
                {adminDiscount > 0 && <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '88%' }}>
                    <Typography style={{
                        fontFamily: 'Nunito',
                        fontWeight: 600,
                        paddingRight: 10,
                        fontSize: 12,
                        flex: 0.5
                    }}>Discount</Typography>
                    <Typography style={{
                        fontFamily: 'Nunito',
                        fontSize: 12,
                        fontWeight: 'bold',
                        paddingRight: 10,
                        textAlign: 'right',
                        flex: 0.5
                    }}  >- S${Math.ceil(adminDiscount)}</Typography>
                </div>}
                {tax !== 0 && <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '88%' }}>
                    <Typography style={{
                        fontFamily: 'Nunito',
                        fontWeight: 600,
                        paddingRight: 10,
                        fontSize: 12,
                        flex: 0.5
                    }}>Tax</Typography>
                    <Typography style={{
                        fontFamily: 'Nunito',
                        fontSize: 12,
                        fontWeight: 'bold',
                        paddingRight: 10,
                        textAlign: 'right',
                        flex: 0.5
                    }}  >S${Math.ceil(tax)}</Typography>
                </div>}

                <Divider orientation="horizontal" sx={{ bgcolor: "#F6F6F6", height: '0px', width: '88%', marginTop: 1 }} flexItem />
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '88%' }}>
                    <Typography style={{ color: '#333333', fontWeight: 'bold', paddingRight: 10, flex: 0.5 }} >Total</Typography>
                    <Typography style={{ color: '#333333', fontWeight: 'bold', fontSize: 16, flex: 0.5, textAlign: 'right', }} >S${Math.ceil(totalPrice)}</Typography>
                </div>

            </Grid>
        )


    }

    return (
        <div style={{ padding: 5 }}>

            <form onSubmit={handleSubmit(onSubmit)}>
                <FormGroup >

                    <Grid container spacing={4} >

                        <Grid xs={12} md={5.8} sx={{ flexGrow: 1 }} justifyContent="center">
                            <BorderBox title={"Available Payment Methods"}>
                                <RadioGroup
                                    row
                                    style={{ paddingBottom: 5, alignItems: 'center', display: 'flex', justifyContent: 'center' }}


                                    sx={{
                                        '& .MuiSvgIcon-root': {
                                            fontSize: 20,
                                        },
                                    }}
                                >
                                    <Typography className='radioText' display={"flex"} alignItems={"center"}>
                                        <FormControlLabel
                                            value="CreditCard"
                                            checked={cardPay ? true : false}
                                            onChange={(e) => enableCardPay(e.target.checked)}
                                            control={<Radio />}
                                            label={<Typography fontFamily={"Nunito"} fontSize={12}>Credit Card</Typography>}
                                        />
                                        {/* <img src={CreditImg} width='15px' height='15px' style={{ marginRight: 10 }} /> */}

                                    </Typography>
                                    {(user && (user?.role === "agent" || (user?.role === "customer" && user?.user?.walletbalance > 0))) &&
                                        <Typography className='radioText' display={"flex"} alignItems={"center"}>
                                            <FormControlLabel
                                                disabled={user?.parentDetails?.walletbalance ? (parseFloat(user?.parentDetails?.walletbalance) < total) ? true : false : user?.walletbalance ? (parseFloat(user?.walletbalance) < total) ? true : false : true}
                                                onChange={(e) => payWallet(e.target.checked)}
                                                value={"Wallet"}
                                                label={<div style={{ display: 'flex', flexDirection: 'column' }}>
                                                    <Typography fontFamily={"Nunito"} fontSize={12}>Wallet <span style={{ fontSize: 10 }}>(Balance {user?.parentDetails?.walletbalance ? `S$ ${parseFloat(user?.parentDetails?.walletbalance).toFixed(2)}` : user?.walletbalance ? `S$ ${parseFloat(user?.walletbalance).toFixed(2)}` : "S$ 0"})</span></Typography>

                                                </div>}
                                                control={<Radio />}
                                            />
                                            {/* <img src={WalletImg} width='15px' height='15px' style={{ marginRight: 10 }} /> */}


                                        </Typography>}
                                    {user && user?.role === "corporate" && <Typography className='radioText' display={"flex"} alignItems={"center"}>
                                        <FormControlLabel value="credit" onChange={(e) => enableCreditPay(e.target.checked)} 
                                        checked={creditPay ? true : false}
                                        control={<Radio />}
                                            label={<Typography fontFamily={"Nunito"} fontSize={12}>Credit Terms</Typography>}
                                        />
                                        {/* <img src={CreditImg} width='15px' height='15px' style={{ marginRight: 10 }} /> */}

                                    </Typography>}
                                </RadioGroup>
                                <Grid container alignItems={'center'} justifyContent="center">
                                    <Grid item md={3} padding={0} paddingLeft={0.5} margin={0}>
                                        <Typography fontFamily={'Nunito'} fontSize={'12px'}>Coupon Code</Typography>
                                    </Grid>

                                    <Grid md={4.7} padding={0} margin={0}>


                                        <InputIcon
                                            // fieldLabel="Add Coupon Code"
                                            fieldName="token"
                                            control={control}
                                            error={errors.token}
                                            Icon={coupon ? <CloseIcon style={{ cursor: 'pointer', height: 30, width: 30, borderRadius: 1, marginRight: 0 }} onClick={cancelAppliedCoupon} /> : <CheckIcon className='checkIcon' style={{ cursor: 'pointer', height: 30, width: 30, borderRadius: 1, marginRight: 0 }}
                                                onClick={verifyToken} />}
                                            fontSize='12px'
                                        />


                                    </Grid>

                                </Grid>

                            </BorderBox>
                        </Grid>

                        <Grid xs={12} md={6} sx={{ height: 'auto', flexGrow: 1, paddingLeft: '2%', paddingRight: '2%' }}>
                            <BorderBox title={"Total Fare"}>
                                {renderPricing()}
                            </BorderBox>
                            {cardPay &&
                                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'start', paddingTop: 20 }}>
                                    <CardDetails />
                                </div>}
                            {walletPay && <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', paddingLeft: '3%', paddingTop: 20 }}>
                                <Button variant="filled"
                                    disabled={loading}
                                    style={{ backgroundColor: loading ? 'gray' : '#333333', borderRadius: 4, padding: 5, height: 30 }} onClick={confirmBooking}>
                                    <Typography className='buttonText'>{loading ? "Loading..." : "CONFIRM BOOKING"}</Typography>
                                </Button>
                            </div>}
                            {creditPay && <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', paddingLeft: '3%', paddingTop: 20 }}>
                                <Button variant="filled"
                                    disabled={loading}
                                    style={{ backgroundColor: loading ? 'gray' : '#333333', borderRadius: 4, padding: 5, height: 30 }} onClick={confirmCreditBooking}>
                                    <Typography className='buttonText'>{loading ? "Loading..." : "CONFIRM BOOKING"}</Typography>
                                </Button>
                            </div>}
                        </Grid>
                    </Grid>
                </FormGroup>
            </form>

        </div>


    )
}

export default PaymentDetails

