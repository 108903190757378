import React, { Fragment, useEffect, useState } from 'react'
import { styled } from '@mui/material/styles';
import './style.scss'
import { Accordion, Button, Card, Stack, Typography, AccordionDetails } from '@mui/material'
import Divider from '@mui/material/Divider'
import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2';
import TimeImg from '../../assets/images/IconsNew/timetable.png'
import Time from '../../assets/images/IconsNew/time.png'
import DistanceImg from '../../assets/images/IconsNew/distance.png'
import ArrowDownImg from '../../assets/images/IconsNew/arrowdown.png'
import moment from 'moment';
import axios from 'axios';
import reactotron from '../../ReactronConfig';
import { GoogleMap, DirectionsRenderer, DirectionsService } from '@react-google-maps/api';
import { useDispatch, useSelector } from 'react-redux';
import MainStop from './MainStop';
import SubStop from './SubStop';
import { BOOKING_DATA, RESET_ERROR } from '../../Redux/constants/bookingConstants';
import MuiAccordionSummary, {
    AccordionSummaryProps,
} from '@mui/material/AccordionSummary';




const SummaryDetails = () => {
    const AccordionSummary = styled((props) => (
        <MuiAccordionSummary
            expandIcon={<img src={ArrowDownImg} style={{ width: 30, height: 30, borderRadius: '15px', backgroundColor: 'white' }} />}
            {...props}
        />
    ))(({ theme }) => ({

        flexDirection: 'row-reverse',
        '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
            transform: 'rotate(180deg)',
            //marginLeft: theme.spacing(0)
            zIndex: 2
        },
        '& .MuiAccordionSummary-expandIconWrapper': {
            zIndex: 2
        },
        '& .MuiAccordionSummary-content': {
            //marginLeft: theme.spacing(-4),  
        },
    }));
    const [distance, setDistance] = useState('')
    const [duration, setDuration] = useState('')
    const [directions, setDirections] = useState('');
    const [load, setLoad] = useState(false);
    const [stops, setStops] = useState(0)

    const dispatch = useDispatch()

    const { bookingData: data, bookingChanged } = useSelector(state => state.booking)



    useEffect(() => {
        //getDirections(data)
        dispatch({
            type: RESET_ERROR
        })
    }, [bookingChanged])


    // const getDirections = async (data) => {
    //     let points = '';
    //     if (data?.waypoints && data?.waypoints?.length > 0) {
    //         data?.waypoints?.map(way => {
    //             points += `%7c${way?.location?.lat},${way?.location?.lng}`;
    //         })
    //     }

    //     let url = points ? `https://maps.googleapis.com/maps/api/directions/json?origin=${data?.location_from?.address}&waypoints=optimize:true${points}&destination=${data?.location_to?.address}&key=${process.env.REACT_APP_GOOGLE_KEY}` : `https://maps.googleapis.com/maps/api/directions/json?origin=${data?.location_from?.address}&destination=${data?.location_to?.address}&key=${process.env.REACT_APP_GOOGLE_KEY}`
    //     if (data?.location_from?.address && data?.location_to?.address) {
    //         await axios.get(url)
    //             .then(response => {
    //                 setDirections(response?.data)
    //                 let distance = 0, duration = 0;
    //                 response?.data.routes[0].legs.map(leg => {
    //                     distance += leg.distance.value
    //                     duration += leg.duration.value
    //                 })
    //                 setDistance(`${(distance / 1000).toFixed(1)} km`);
    //                 setDuration(`${(duration / 60).toFixed(1)} min`)
    //             })
    //             .catch(err => {

    //             })
    //     }
    // }

    const removeExtraStop = async (index) => {
        let details = data.stops.filter((item, i) => i !== index);
        data.stops = details
        data.waypoints = details.map(way => {
            return {
                location: {
                    lat: way?.stop_name?.latitude,
                    lng: way?.stop_name?.longitude
                }
            }
        });
        await localStorage.setItem("booking", JSON.stringify(data));

        dispatch({
            type: BOOKING_DATA,
            payload: data
        })
        //onAdd()

    }


    const directionsCallback = (response) => {
        

        if (response !== null) {
            if (response.status === 'OK') {
                if (!directions || data?.stops.length !== stops) {
                    setStops(data?.stops?.length)
                    setDirections(response)
                }

                let distance = 0, duration = 0;
                response.routes[0].legs.map(leg => {
                    distance += leg.distance.value
                    duration += leg.duration.value
                })
                setDistance(`${(distance / 1000).toFixed(1)} km`);
                setDuration(`${(duration / 60).toFixed(1)} min`)
            } else {
                
            }
        }
    }

    const containerStyle = {
        width: '30vw',
        borderBottomLeftRadius: '45px',
        borderBottomRightRadius: '45px',
        height: '300px',
    };

    return (
        <div style={{ padding: 4 }}>            
            <Grid container>
                <Grid xs={12} md={data?.bookingType === 'return' ? 4 : 6} borderRadius={{ xs: "25px 25px 0px 0px", md: "25px 0px 0px 0px" }} sx={{ bgcolor: '#F4F4F4', borderTopLeftRadius: 25, padding: 1 }} marginBottom={{ xs: 3, md: 0 }}   >
                    {/* <Grid xs={12} md={6} sx={{ bgcolor: '#F4F4F4', height: 'auto',flexGrow:1,  borderRadius: 5, padding: 1 }} marginLeft={{ xs: 0, md: -3.5 }}> */}
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', padding: '1%' }}>
                            <span>
                                <img src={TimeImg} style={{ width: 12, height: 12, marginRight: 10 }} />
                            </span>
                            <span>
                                <Typography className='loctext'>Pick Up Date :<span style={{ fontFamily: 'Nunito', fontWeight: 'bold',width:300 }}> {moment(data?.pickupdate).local().format("DD-MM-YYYY")}</span></Typography>
                            </span>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', padding: '1%' }}>
                            <span>
                                <img src={Time} style={{ width: 12, height: 12, marginRight: 10 }} />
                            </span>
                            <span>
                                <Typography className='loctext'>Pick Up  Time :<span style={{ fontFamily: 'Nunito', fontWeight: 'bold' }}> {moment(data?.pickupdate).local().format("hh:mm A")}</span></Typography>
                            </span>
                        </div>
                        {!data?.location_to?.address &&
                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', padding: '1%' }}>
                                <span>
                                    <img src={Time} style={{ width: 12, height: 12, marginRight: 10 }} />
                                </span>
                                <span>
                                    <Typography className='loctext'>Hours :<span style={{ fontFamily: 'Nunito', fontWeight: 'bold' }}> {data?.duration}</span></Typography>
                                </span>
                            </div>}
                    </div>
                    {data?.location_to?.address &&
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'start', padding: '1%' }}>
                            <span>
                                <img src={DistanceImg} style={{ width: 12, height: 12, marginRight: 10 }} />
                            </span>

                            <span>
                                <Typography className='loctext'>Estimated Distance & Time :<span style={{ fontFamily: 'Nunito', fontWeight: 'bold' }}>{distance}, {duration}</span></Typography>
                            </span>
                        </div>}
                        <div style={{ display: 'flex', flexDirection: 'row' }}> 
                        {data?.bookingType === 'return' &&
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', padding: '1%' }}>
                            <span>
                                <img src={TimeImg} style={{ width: 12, height: 12, marginRight: 5 }} />
                            </span>
                            <span>
                                <Typography className='loctext'>Return Date :<span style={{ fontFamily: 'Nunito', fontWeight: 'bold' }}> {moment(data?.returndate).local().format("DD-MM-YYYY")}</span></Typography>
                            </span>
                        </div>
                        
                    }
                    {data?.bookingType === 'return' &&
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', padding: '1%' }}>
                            <span>
                                <img src={TimeImg} style={{ width: 12, height: 12, marginRight: 5 }} />
                            </span>
                            <span>
                                <Typography className='loctext'>Return Time :<span style={{ fontFamily: 'Nunito', fontWeight: 'bold' }}> {moment(data?.returndate).local().format("hh:mm A")}</span></Typography>
                            </span>
                        </div>
                        
                    }
                        </div>
                    
                </Grid>
                <Grid xs={12} md={data?.bookingType === 'return' ? 8 : 6} sx={{ bgcolor: '#F4F4F4', height: 'auto', flexGrow: 1, borderTopRightRadius: 25, padding: 1 }} borderRadius={{ xs: "25px 25px 0px 0px", md: "0px 25px 0px 0px" }}   borderLeft={{ xs: 0, md: "5px solid #FFFFFF " }} >
                    <Stack flexDirection={{ xs: 'column', md: 'row' }} justifyContent="space-between" alignItems={"center"}>
                        <Grid xs={12} md={data?.bookingType === 'return' ? 8 : 12} >    
                            {data?.bookingType === 'return' &&
                                <Typography className='loctext' style={{ fontWeight: 'bold', paddingLeft: '4%', paddingBottom: '1%' }}>
                                    One-way Details
                                </Typography>}
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <MainStop
                                    address={data?.location_from?.address}
                                    count={1}
                                    color="green"
                                />
                                {data?.location_to?.address &&
                                    <div style={{ border: '1px dashed #B6A269', borderLeftWidth: 3, borderRightWidth: 0, borderTopWidth: 0, borderBottomWidth: 0, marginLeft: 10, minHeight: 5 }}>
                                        {data?.stops?.map((stop, index) => (
                                            <SubStop
                                                address={stop?.stop_name?.address}
                                                count={index + 2}
                                                color="blue"
                                                index={index}
                                                removeStop={(index) => removeExtraStop(index)}
                                                stopNote={stop?.stopNote}
                                            />
                                        ))}
                                    </div>}
                                {data?.location_to?.address &&
                                    <MainStop
                                        address={data?.location_to?.address}
                                        count={data?.stops ? data?.stops?.length + 1 : 2}
                                        color="red"
                                    />}
                            </div>
                            {/* <MainStop 
                            address={data?.location_from?.address} 
                            count={1} 
                            color="green" 
                        />
                        <Divider orientation="vertical" flexItem sx={{ width:'0px',height: 'auto',marginLeft:1.2,marginTop:-3,marginBottom:-1 ,paddingBottom:2,minHeight:20,borderRight:'2px dashed' ,borderLeft:'none',borderRightColor: "#B6A269",}} >
                        {data?.stops?.map((stop, index) => (
                            <SubStop 
                                address={stop?.stop_name?.address} 
                                count={index + 2} 
                                color="blue"
                                index={index}
                                removeStop={(index) => removeExtraStop(index)}
                                stopNote={stop?.stopNote}
                            />
                        ))}
                        </Divider>
                        <MainStop 
                            address={data?.location_to?.address} 
                            count={data?.stops ? data?.stops?.length + 2 : 2} 
                            color="red"
                        /> */}
                        </Grid>
                        {data?.bookingType === 'return' &&
                            <Grid xs={12} md={8} >
                                <Typography className='loctext' style={{ fontWeight: 'bold', paddingLeft: '4%', paddingBottom: '1%' }}>
                                    Return Details
                                </Typography>
                                <div style={{ 
                                    //borderLeft: '0.5px solid #ccc', borderRight: '0.5px solid #ccc', 
                                    display: 'flex', flexDirection: 'column' }}>
                                    <MainStop
                                        address={data?.location_to?.address}
                                        count={1}
                                        color="green"
                                    />
                                    <div style={{ border: '1px dashed #B6A269', borderLeftWidth: 3, borderRightWidth: 0, borderTopWidth: 0, borderBottomWidth: 0, marginLeft: 10, minHeight: 5 }}>
                                        {data?.stops?.map((stop, index) => (
                                            <SubStop
                                                address={stop?.stop_name?.address}
                                                count={index + 2}
                                                color="blue"
                                                index={index}
                                                removeStop={(index) => removeExtraStop(index)}
                                                stopNote={stop?.stopNote}
                                            />
                                        ))}
                                    </div>
                                    <MainStop
                                        address={data?.location_from?.address}
                                        count={data?.stops ? data?.stops?.length + 1 : 2}
                                        color="red"
                                    />
                                </div>
                            </Grid>}
                    </Stack>
                </Grid>

                {data?.location_to?.address &&
                    <Accordion style={{ paddingTop: '0.5%', paddingBottom: 0, boxShadow: 'none', left: -20, border: '0px' }} >
                        <AccordionSummary >
                            <Stack>
                                <Button
                                    onClick={() => setLoad(!load)}
                                    variant="contained" justifyContent='center'
                                    style={{ color: '#fff', backgroundColor: '#B6A269', borderRadius: 20, height: 40, width: 130, left: -35, zIndex: 2 }} >

                                    <h4 color='#fff' className='mapText'>View Map</h4>
                                </Button>
                            </Stack>
                            <Box width={{ xs: '50%', md: "100%" }}>
                                <Divider orientation="horizontal" sx={{ bgcolor: "#B6A269", height: '2px', marginTop: 2, width: '103.9%', marginLeft: -5 }} />
                            </Box>
                        </AccordionSummary>

                        <AccordionDetails sx={{ position: 'relative', borderRadius: '15px', display: 'flex', justifyContent: 'center', width: '89vw', alignItems: 'center' }}>

                            <GoogleMap
                                mapContainerStyle={containerStyle}
                                zoom={0}
                                clickableIcons={false}
                                        options={{
                                            scrollwheel: false,
                                            disableDoubleClickZoom: true,
                                            zoomControl: false,
                                            gestureHandling: 'none',
                                            fullscreenControl: false,
                                            streetView: null,
                                            streetViewControl: false,
                                            mapTypeControl: false,
                                            disableDefaultUI: true,
                                            styles: [{ elementType: "labels", featureType: "poi.business", stylers: [{ visibility: "off", }], }]
                                        }}
                            >
                                {load && <>
                                    <DirectionsService
                                        options={{
                                            destination: data?.location_to?.address,
                                            origin: data?.location_from?.address,
                                            waypoints: data?.waypoints,
                                            optimizeWaypoints: false,
                                            travelMode: 'DRIVING',
                                        }}
                                        callback={directionsCallback}
                                        onLoad={directionsService => {
                                          
                                        }}
                                        onUnmount={directionsService => {
                                         
                                        }}
                                    />
                                    {directions && <DirectionsRenderer
                                        // required
                                        options={{
                                            directions: directions
                                        }}
                                        // optional
                                        onLoad={directionsRenderer => {
                                            // console.log('DirectionsRenderer onLoad directionsRenderer: ', directionsRenderer)
                                        }}
                                        // optional
                                        onUnmount={directionsRenderer => {
                                            // console.log('DirectionsRenderer onUnmount directionsRenderer: ', directionsRenderer)
                                        }}
                                    />}
                                </>}
                            </GoogleMap>
                        </AccordionDetails>
                    </Accordion>}
            </Grid>

        </div>

    )
}

export default SummaryDetails

