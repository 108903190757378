import React from 'react'
import './style.scss'
import { Grid, Typography, Button, Snackbar, Alert, CircularProgress } from '@mui/material'
import InputField from '../../components/InputField'
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { useDispatch, useSelector } from 'react-redux'
import customAxios from '../../CustomAxios';
import { toast } from 'react-toastify';
import { LOADING } from '../../Redux/constants/userConstants';

const ForgotPassword = () => {

    const [open, setOpen] = React.useState(false);
	const dispatch = useDispatch();

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
        return;
        }
        setOpen(false);
        //navigate("/resetpassword")
    };

	const schema = yup.object({
		email: yup.string().email('Invalid email format').required('Email required'),
	}).required();

	const navigate = useNavigate();
	const { register, handleSubmit, control, formState: { errors } } = useForm({
		resolver: yupResolver(schema)
	});
    const { loading, isAuthenticated, error } = useSelector(state => state.auth)

	
	const onSubmit = async(data) => {
		dispatch({
			type: LOADING,
			payload: true
		})
		await customAxios.post(`auth/forgot-password`, data)
		.then(async response => {
			dispatch({
				type: LOADING,
				payload: false
			})
			setOpen(true)
			
		})
		.catch(async error => {
			toast.error(error)

			dispatch({
				type: LOADING,
				payload: false
			})

		});
		
	};

	return (
		<div>
			<Grid container>
				<Grid xs={12} md={7} sx={{  flexDirection: 'row', justifyContent: 'start', alignItems: 'center',cursor:'pointer' }} display={{ xs: "none", md: "block" }} className=" loginBackground" onClick={()=>navigate('/')}>

				</Grid>
					<Snackbar open={open} autoHideDuration={2000} onClose={handleClose}>
                        <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
                            Reset password link has been successfully sent
                        </Alert>
                    </Snackbar>
				<Grid xs={12} md={5} className=" forgot" >
                    
					<form onSubmit={handleSubmit(onSubmit)}>
						<div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'start', alignItems: 'center' }}>
							<h2 className='loginheading'>FORGOT PASSWORD</h2>
                            <Typography width={{ xs: "95%", md: '60%'}} className='desText' >Please enter your registered email address. You will receive a link to create a new password via email.</Typography>
							<Grid md={12} width={{ xs: "95%", md: '50%'}}>
							<InputField 
								fieldLabel="Email Address" 
								fieldName="email" 
								control={control} 
								error={errors.email} 
							/>
							</Grid>
							
						</div>
						
						
						<div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center',paddingTop:'2%'  }}>
							{loading ? <CircularProgress /> : <Button type="submit" 
							sx={{ boxShadow: 2 }}
							variant="filled" style={{ backgroundColor: '#333333', borderRadius: 0, padding: 5}}
							>
								<Typography className='loginButton'>SEND</Typography>
							</Button>}
							
						</div>
					</form>
				</Grid>
			</Grid>
		</div>
	)
}

export default ForgotPassword
