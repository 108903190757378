import moment from "moment";
import { isArray } from "lodash";
import reactotron from "../ReactronConfig";

export function calculateExtra(item, date, type, duration, rate, mode){
    reactotron.log({item: item?.extraChargesNew, date, type, duration, rate, mode})
        let extras = 0 ;
        let extraArray = [];
        if(isArray(item?.extraChargesNew)){
            item?.extraChargesNew?.map(extra => {
    
                let rateType = extra?.rate_type
                //text
                if(extra?.input_type === "text"){
                    
                    if(rateType === "percentage"){
                        extras += type === "hour" ? Math.ceil(((rate * duration)/100) * parseFloat(extra?.rate)) : Math.ceil(parseFloat(rate)/100 *parseFloat(extra?.rate))

                        extraArray.push({
                            id: extra?.add_on_item_id,
                            name: extra?.addonitem?.name,
                            price: type === "hour" ? Math.ceil(((rate * duration)/100) * parseFloat(extra?.rate)) : Math.ceil(parseFloat(rate)/100 *parseFloat(extra?.rate))
                        })
                    }
                    else if(rateType === "flatrate"){
                        extras += type === "hour" ? Math.ceil(parseFloat(extra?.rate) * duration) :  Math.ceil(extra?.rate)
                        extraArray.push({
                            id: extra?.add_on_item_id,
                            name: extra?.addonitem?.name,
                            price: type === "hour" ? Math.ceil(parseFloat(extra?.rate) * duration) :  Math.ceil(extra?.rate)
                        })
                    }
                    else if(rateType === "times"){
                        extras += type === "hour" ? Math.ceil((rate * duration) * parseFloat(extra?.rate) - (rate * duration)) : parseFloat(rate * parseFloat(extra?.rate) - rate)
                        extraArray.push({
                            id: extra?.add_on_item_id,
                            name: extra?.addonitem?.name,
                            price: type === "hour" ? Math.ceil((rate * duration) * parseFloat(extra?.rate) - (rate * duration)) : parseFloat(rate * parseFloat(extra?.rate) - rate)
                        })
                    }
                }
    
                //date
                if(extra?.input_type === "date"){
                    const date1 = moment(extra?.label1, "MM/DD/YYYY");
                    let checkTime = moment(date)
                    // Check if the time is between the start and end times
                    const isBetween = moment(date1).isSame(checkTime, 'day')
                    let rateType = extra?.rate_type
    
                    //console.log({isBetween}, "date", date1, checkTime)
    
                    if(isBetween){
                        if(rateType === "percentage"){
                            extras += type === "hour" ? Math.ceil(parseFloat((rate * duration)/100) * parseFloat(extra?.rate)) : Math.ceil(parseFloat(rate/100) * parseFloat(extra?.rate))
    
                            extraArray.push({
                                id: extra?.add_on_item_id,
                                name: extra?.addonitem?.name,
                                price: type === "hour" ? Math.ceil(parseFloat((rate * duration)/100) * parseFloat(extra?.rate)) : Math.ceil(parseFloat(rate/100) * parseFloat(extra?.rate))
                            })
                        }
                        else if(rateType === "flatrate"){
                            extras += type === "hour" ? Math.ceil(parseFloat(extra?.rate) * duration) :  Math.ceil(extra?.rate)
                            extraArray.push({
                                id: extra?.add_on_item_id,
                                name: extra?.addonitem?.name,
                                price: type === "hour" ? Math.ceil(parseFloat(extra?.rate) * duration) :  Math.ceil(extra?.rate)
                            })
                        }
                        else if(rateType === "times"){
                            extras += type === "hour" ? Math.ceil((rate * duration) * parseFloat(extra?.rate) - (rate * duration)) : Math.ceil(rate * parseFloat(extra?.rate) - rate)
                            extraArray.push({
                                id: extra?.add_on_item_id,
                                name: extra?.addonitem?.name,
                                price: type === "hour" ? Math.ceil((rate * duration) * parseFloat(extra?.rate) - (rate * duration)) : Math.ceil(rate * parseFloat(extra?.rate) - rate)
                            })
                        }
                    }
                }
    
    
                //timerange
                if(extra?.input_type === "timerange"){
                    let endDate, startDate, hours = duration;
                    let startTime, endTime;
                    let label1 =extra?.label1; 
                    let label2 =extra?.label2; 
                    
                    let day = moment(date).format("A");
                    if(day?.toLowerCase().includes("am")){
                        if(label1?.toLowerCase().includes("pm") && label2?.toLowerCase().includes("am")){
                            startTime = moment(`${moment(date).format("DD-MM-YYYY")} ${label1}`, "DD-MM-YYYY hh:mm A").add(-1, 'day');
                            endTime = moment(`${moment(date).format("DD-MM-YYYY")} ${label2}`, "DD-MM-YYYY hh:mm A");
                        }
                        else {
                            startTime = moment(`${moment(date).format("DD-MM-YYYY")} ${label1}`, "DD-MM-YYYY hh:mm A");
                            endTime = moment(`${moment(date).format("DD-MM-YYYY")} ${label2}`, "DD-MM-YYYY hh:mm A")
                        }
                    }
                    else{
                        if(label1?.toLowerCase().includes("pm") && label2?.toLowerCase().includes("am")){
                            startTime = moment(`${moment(date).format("DD-MM-YYYY")} ${label1}`, "DD-MM-YYYY hh:mm A")
                            endTime = moment(`${moment(date).format("DD-MM-YYYY")} ${label2}`, "DD-MM-YYYY hh:mm A").add(1, 'day');
                        }
                        else{
                            startTime = moment(`${moment(date).format("DD-MM-YYYY")} ${label1}`, "DD-MM-YYYY hh:mm A")
                            endTime = moment(`${moment(date).format("DD-MM-YYYY")} ${label2}`, "DD-MM-YYYY hh:mm A");
                        }
                    }
                    if(type === "hour"){
                        startDate = moment(date);
                        endDate = moment(date).add(hours, "hour");

                        // Determine the maximum start time and minimum end time
                        const maxStartTime = moment.max(startDate, startTime);
                        const minEndTime = moment.min(endDate, endTime);

                        // Calculate the difference between the maximum start time and minimum end time
                        const duration = moment.duration(minEndTime.diff(maxStartTime));

                        // Convert the duration into hours
                        hours = duration.asHours();
                    }

                    let checkTime = moment(date)
                    // Check if the time is between the start and end times
                    const isBetween = checkTime.isBetween(startTime, endTime, undefined, '[]');

                    //reactotron.log({isBetween,startTime: moment(startTime).format("DD-MM-YYYY hh:mm A"),  endTime: moment(endTime).format("DD-MM-YYYY hh:mm A"), date: moment(checkTime).format("DD-MM-YYYY hh:mm A")})
                    
                    //console.log({isBetween, startTime: startTime.format("DD-MM-YYYY hh:mm A"), endTime: endTime.format("DD-MM-YYYY hh:mm A")})
    
                    if(isBetween){
                        if(rateType === "percentage"){
                            extras += type === "hour" ?  Math.ceil(((rate/100) * hours) * parseFloat(extra?.rate)) :  Math.ceil((rate/100) * parseFloat(extra?.rate)) 
    
                            extraArray.push({
                                id: extra?.add_on_item_id,
                                name: extra?.addonitem?.name,
                                price: type === "hour" ? Math.ceil(((rate/100) * hours) * parseFloat(extra?.rate)) :  Math.ceil((rate/100) * parseFloat(extra?.rate))
                            })
                        }
                        else if(rateType === "flatrate"){
                            extras += type === "hour" ? Math.ceil(extra?.rate * hours) :  Math.ceil(extra?.rate)
                            extraArray.push({
                                id: extra?.add_on_item_id,
                                name: extra?.addonitem?.name,
                                price: type === "hour" ? Math.ceil(extra?.rate * hours) :  Math.ceil(extra?.rate)
                            })
                        }
                        else if(rateType === "times"){
                            extras += type === "hour" ? Math.ceil((rate * hours) *parseFloat(extra?.rate) - (rate * hours)) : Math.ceil(rate * parseFloat(extra?.rate) - rate)
                            extraArray.push({
                                id: extra?.add_on_item_id,
                                name: extra?.addonitem?.name,
                                price: type === "hour" ? Math.ceil((rate * hours) *parseFloat(extra?.rate) - (rate * hours)) : Math.ceil(rate * parseFloat(extra?.rate) - rate)
                            })
                        }
                    }
                }
    
    
                //daterange
                if(extra?.input_type === "daterange"){
                    let dates = extra?.label1.split('-')
                    let date1 = moment(dates[0].trim(), "DD/MM/YYYY");
                    let date2 = moment(dates[1].trim(), "DD/MM/YYYY");
    
                    const isBetween =moment(date).isBetween(date1, date2, null, '[]');
    
    
                   //console.log({isBetween})
                    
    
                    if(isBetween){
                        if(rateType === "percentage"){
                            extras += type === "hour" ? Math.ceil(parseFloat((rate * duration)/100) * parseFloat(extra?.rate)) : Math.ceil(parseFloat(rate/100) *parseFloat(extra?.rate))
    
                            extraArray.push({
                                id: extra?.add_on_item_id,
                                name: extra?.addonitem?.name,
                                price: type === "hour" ? Math.ceil(parseFloat((rate * duration)/100) * parseFloat(extra?.rate)) : Math.ceil(parseFloat(rate/100) *parseFloat(extra?.rate))
                            })
                        }
                        else if(rateType === "flatrate"){
                            extras += type === "hour" ? Math.ceil(parseFloat(extra?.rate) * duration) :  Math.ceil(extra?.rate)
                            extraArray.push({
                                id: extra?.add_on_item_id,
                                name: extra?.addonitem?.name,
                                price: type === "hour" ? Math.ceil(parseFloat(extra?.rate) * duration) :  Math.ceil(extra?.rate)
                            })
                        }
                        else if(rateType === "times"){
                            extras += type === "hour" ? Math.ceil((rate * duration) * parseFloat(extra?.rate) - (rate * duration)) : Math.ceil(rate * parseFloat(extra?.rate) - rate)
                            extraArray.push({
                                id: extra?.add_on_item_id,
                                name: extra?.addonitem?.name,
                                price: type === "hour" ? Math.ceil((rate * duration) * parseFloat(extra?.rate) - (rate * duration)) : Math.ceil(rate * parseFloat(extra?.rate) - rate)
                            })
                        }
                    }
                }
    
    
                //multidate
                if(extra?.input_type === "multidate"){
                    let dates = extra?.label1.split(',');
                    let isBetween;
                    dates?.map(da => {
                        //console.log({first: moment(da, "MM/DD/YYYY").format("DD-MM-YYYY"), date: moment(bookingData?.pickupdate).format("DD-MM-YYYY"), da})
                        if (moment(date).isSame(moment(da, "MM/DD/YYYY"), 'day')) {
                            //console.log("same")
                            isBetween = true
                        }
                    })
                    if(isBetween){
                        if(rateType === "percentage"){
                            extras += type === "hour" ? Math.ceil(((rate * duration)/100) *parseFloat(extra?.rate)) : Math.ceil((rate/100) * parseFloat(extra?.rate))
    
                            extraArray.push({
                                id: extra?.add_on_item_id,
                                name: extra?.addonitem?.name,
                                price: type === "hour" ? Math.ceil(((rate * duration)/100) *parseFloat(extra?.rate)) : Math.ceil((rate/100) * parseFloat(extra?.rate))
                            })
                        }
                        else if(rateType === "flatrate"){
                            extras += type === "hour" ? Math.ceil(parseFloat(extra?.rate) * duration) :  Math.ceil(extra?.rate)
                            extraArray.push({
                                id: extra?.add_on_item_id,
                                name: extra?.addonitem?.name,
                                price: type === "hour" ? Math.ceil(parseFloat(extra?.rate) * duration) :  Math.ceil(extra?.rate)
                            })
                        }
                        else if(rateType === "times"){
                            //reactotron.log("in")
                            extras += type === "hour" ? Math.ceil((rate * duration) * parseFloat(extra?.rate) - (rate * duration)) : Math.ceil(rate * parseFloat(extra?.rate) - rate)
                            extraArray.push({
                                id: extra?.add_on_item_id,
                                name: extra?.addonitem?.name,
                                price: type === "hour" ? Math.ceil((rate * duration) * parseFloat(extra?.rate) - (rate * duration)) : Math.ceil(rate * parseFloat(extra?.rate) - rate)
                            })
                        }
                    }
                }
            })
        }

       // reactotron.log({extras, extraArray})
        if(mode === "price"){
            return extras;
        }
        else{
            return extraArray;
        }
}