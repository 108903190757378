import { FormGroup, TextField, Typography } from '@mui/material'
import React from 'react'
import { Controller } from 'react-hook-form'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/plain.css'

const PhoneInputs = ({ fieldName, fieldType, fieldLabel, placeholder, width, fontSize, control, error, children, defaultValue, rows, multiline, min, readonly, helperText, required ,contryCode, onChangePhone}) => {

  const handleChange = (value, data) => {
 
     console.log({data})

      let values = {
        callingCode: `+${data.dialCode}`,
        phonenumber: value.slice(data.dialCode.length)
      }

      onChangePhone(values)
      
  }

  return (
        <FormGroup style={{ marginRight: 2, marginLeft:4, marginBottom: 1 }}>
			<Typography className='formText' sx={{ fontSize:  '12px' }} >{fieldLabel}{required &&<span style={{ color: 'red' }}>*</span>}</Typography>
			<Controller
				name={fieldName}
				control={control ? control : null}
				render={({ field: { onBlur, onChange, value } }) => <PhoneInput
                    // inputStyle={{
                    //     width: '99%',
                    //     border: '1px solid #ccc',
                    //     backgroundColor: '#fff',
                    //     borderRadius: 4,
                    //     height: 12,
                    //     fontSize: 12,
                    //     color: '#3B4958'
                    // }}
                    style={{ height: 32, margin: 0, padding: 0 }}
                    placeholder="."
                    country={'sg'}
                    
                    value={value}
                    onChange={(value, data)=>{
                      if(onChangePhone){
                        handleChange(value, data)
                      }
                      onChange(value)
                    }
                    }
                />}
			/>
			{error && <Typography color={"red"} fontSize={10}>{error?.message}</Typography>}
		</FormGroup>
    )
}

export default PhoneInputs