import React, { useState } from 'react'
import BorderBox from '../../components/BorderBox'
import { Button, FormControlLabel, Grid, Radio, RadioGroup, Typography } from '@mui/material'
import reactotron from '../../ReactronConfig'
import { useSelector } from 'react-redux'
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js'
import moment from 'moment'
import customAxios from '../../CustomAxios'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'

const PriceBreakup = ({ base, addOnItem, addOnService, agentMarkup, adminMarkup, adminDiscount, couponDiscount, extraSeats, tax, toll, total, priceList, oldTrip, bookingData, getValues, arrival, departure, selectedVehicle, baseTrips, setLoading, balanceAmount }) => {

    const { user } = useSelector(state => state.auth)

    const stripe = useStripe();
    const elements = useElements();

    const navigate = useNavigate()

    

    const [mode, setMode] = useState('card')

    const [isPaymentLoading, setIsPaymentLoading] = useState(false)

    reactotron.log({ oldTrip })


    const updatePayment = async() => {

        let stops = bookingData?.stops;

        let savedStops = [];

        stops?.map((stop, index) => {
            savedStops?.push({
                cityname: stop?.stopName?.description,
                latitude: stop?.stopName?.latitude,
                longitude: stop?.stopName?.longitude,
            })
        })

        reactotron.log({stops})
        //return false;
        // let data = getValues();
        // reactotron.log({data})
        setIsPaymentLoading(true)
        try {
            let seletedTrip = baseTrips?.find(base => base?.id === selectedVehicle?.id)

            let datas = getValues()

            let addOn = 0;
        
            selectedVehicle?.newAddOns?.filter((add) => add.checked).map((add) => {
                addOn +=add?.price
            })

            let trip = {
                customer_reference: oldTrip?.customer_reference,
                arrival: bookingData?.type === 'point' ? datas?.location_from?.type === 'airport' ? 'Arrival' : datas?.location_to?.type === 'airport' ? 'Departure' : 'Point to Point' : 'Hourly',
                id: oldTrip?.id,
                location_from: datas?.location_from?.address,
                location_to: datas?.location_to?.address,
                baggage_protection: (bookingData?.location_to?.type === "airport" && datas?.country === "SG") ? true : false,
                name: datas?.passenger_name,
                country: datas?.country,
                email: datas?.email,
                phone: datas?.mobile,
                mobile: datas?.mobile,
                namesign: "",
                nationality: "",
                passport: "",
                adults: datas?.adults ? datas?.adults : 0,
                childrens: datas?.childrens ? datas?.childrens : 0,
                cruise_no: datas?.cruise_no,
                disembarkment: datas?.disembarkment ? moment(datas?.disembarkment).format("hh:mm A") : null,
                minors: datas?.minors ? datas?.minors : 0,
                booster_seats: datas?.minors ? datas?.minors : 0,
                baby_seats: datas?.baby ? datas?.baby : 0,
                suitcase: datas?.luggage ? datas?.luggage : 0,
                carryon: datas?.hand_carry ? datas?.hand_carry : 0,
                specialinstructions: datas?.specialinstructions ? datas?.specialinstructions : 0,
                pickupdate: moment(datas?.pickupdate).format("YYYY-MM-DD"),
                pickuptime: moment(datas?.pickupdate).format("hh:mm A"),
                pickup_loc_type: datas?.arrival_method,
                flightno: datas?.flight_no ? datas?.flight_no : null,
                flighTiming: datas?.flight_date ? datas?.flight_date : null,
                flightdate: datas?.flight_date ? moment(datas?.flight_date).format("DD-MM-YYYY") : null,
                flighttime: datas?.flight_date ? moment(datas?.flight_date).format("hh:mm A") : null,
                time_related:  datas?.time_related ? datas?.time_related : null,
                buffer_time: datas?.time_related ? datas?.time_related : null,
                supplier_id: oldTrip?.supplier_id ? oldTrip?.supplier_id : null,
                vehicle_type: selectedVehicle?.vehicleTypeId,
                vehicle_id: selectedVehicle?.vehicleId,
                driver_id: selectedVehicle?.driverId,
                customer_id: user?.parentDetails ? user?.parentDetails?.id : user?.id ? user?.id : null,
                created_by: user?.id ? user?.id : null,
                currency: oldTrip?.currency,
                usertype: user ? user?.role : "guest",
                stops: savedStops,
                stopCount: bookingData?.stops ? bookingData?.stops?.length : 0,
                payment_type: "card",
                arival: arrival ? arrival : null,
                booking_data: bookingData,
                selectedVehicle: seletedTrip,
                bookingType: 'PAL',
                addonItem: selectedVehicle?.extraArray?.filter(item => item?.checked),
                addonservices: selectedVehicle?.newAddOns?.filter(it => it?.checked),
                taxPer: selectedVehicle?.tax,
                vehicletype: selectedVehicle?.vehicleType,
                service_type: seletedTrip?.service_type,
                vehicle_image: seletedTrip?.vehicledetails?.vehicle_images?.length > 0 ? `${seletedTrip?.imageBasePath}${seletedTrip?.vehicledetails?.vehicle_images[0].path}` : null,
                agentMarkup: selectedVehicle?.agentmarkup,
                base_rate: selectedVehicle?.baseRate,
                markup: selectedVehicle?.adminMarkup,
                tax: selectedVehicle?.tax,
                total_price: selectedVehicle?.total,
                toll: selectedVehicle?.toll,
                extraCharges: selectedVehicle?.extras,
                addonService: addOn,
                //itenary: bookingData?.type === "hour" ? itenary?.itenary : null,
                //itenary_id: bookingData?.type === "hour" ? itenary?.id : null,
                adminDiscount: selectedVehicle?.adminDiscount,
                departure_airport_type: departure,
                departure_flight_no: datas?.departure_flight_no,
                departure_flight_date: datas?.departure_flight_date,
                offset: bookingData?.location_from?.offset,
                offsetdatetime: moment(moment(datas?.pickupdate).utcOffset(datas?.location_from?.offset)).utcOffset(0).format("YYYY-MM-DD HH:mm:ss"),
                extraSeat: selectedVehicle?.extraSeat,
                booking_id: oldTrip?.booking_id
            }

            let data = {
                trip_id: oldTrip?.id,
                trips: [trip],
                booking_total: selectedVehicle?.total,
                payment_type: oldTrip?.payment_type,
                usertype: user?.role ? user?.role : null,
                customer_id: user?.parentDetails ? user?.parentDetails?.id : user?.id ? user?.id : null,
                created_by: user?.id ? user?.id : null,
                currency: oldTrip?.currency,
                transactionID: null,
                paymentStatus: null,
                code: null,
                customer_type: user?.customer_type ? user?.customer_type : 0,
                couponDiscount: 0,
                base_total: selectedVehicle?.baseRate,
                extraCharges: selectedVehicle?.extras,
                tax: selectedVehicle?.tax,
                addOn: addOn,
                agentMarkup: selectedVehicle?.agentmarkup,
                toll: selectedVehicle?.toll,
                adminMarkup: selectedVehicle?.adminMarkup,
                bookingType: 'PAL',
                customer_stripe_id: null,
                payment_method_id: null,
                extraSeat:  selectedVehicle?.extraSeat,
                adminDiscount: selectedVehicle?.adminDiscount,
            }


            let tripResponse = await customAxios.post(`update-trip`, data);
        
            if(tripResponse?.status === 200){
                toast.success(tripResponse?.data?.message)
                navigate('/dashboard')
            }
            else{
                throw tripResponse?.data?.message
            }
        } catch (error) {
            toast.error(error)
        }
        finally{
            setIsPaymentLoading(false)
        }


    }


    const makePayment = async() => {

        let stops = bookingData?.stops;

        let savedStops = [];

        stops?.map((stop, index) => {
            savedStops?.push({
                cityname: stop?.stopName?.description,
                latitude: stop?.stopName?.latitude,
                longitude: stop?.stopName?.longitude,
            })
        })
        setIsPaymentLoading(true)
        setLoading(true)

        let seletedTrip = baseTrips?.find(base => base?.id === selectedVehicle?.id)

        let datas = getValues()

        let addOn = 0;
    
        selectedVehicle?.newAddOns?.filter((add) => add.checked).map((add) => {
            addOn +=add?.price
        })
        let trip = {
            customer_reference: oldTrip?.customer_reference,
            arrival: bookingData?.type === 'point' ? datas?.location_from?.type === 'airport' ? 'Arrival' : datas?.location_to?.type === 'airport' ? 'Departure' : 'Point to Point' : 'Hourly',
            id: oldTrip?.id,
            location_from: datas?.location_from?.address,
            location_to: datas?.location_to?.address,
            baggage_protection: (datas?.location_to?.type === "airport" && datas?.country === "SG") ? true : false,
            name: datas?.passenger_name,
            country: datas?.country,
            email: datas?.email,
            phone: datas?.mobile,
            mobile: datas?.mobile,
            namesign: "",
            nationality: "",
            passport: "",
            adults: datas?.adults ? datas?.adults : 0,
            childrens: datas?.childrens ? datas?.childrens : 0,
            cruise_no: datas?.cruise_no,
            disembarkment: datas?.disembarkment ? moment(datas?.disembarkment).format("hh:mm A") : null,
            minors: datas?.minors ? datas?.minors : 0,
            booster_seats: datas?.minors ? datas?.minors : 0,
            baby_seats: datas?.baby ? datas?.baby : 0,
            suitcase: datas?.luggage ? datas?.luggage : 0,
            carryon: datas?.hand_carry ? datas?.hand_carry : 0,
            specialinstructions: datas?.specialinstructions ? datas?.specialinstructions : 0,
            pickupdate: moment(datas?.pickupdate).format("YYYY-MM-DD"),
            pickuptime: moment(datas?.pickupdate).format("hh:mm A"),
            pickup_loc_type: datas?.arrival_method,
            flightno: datas?.flight_no ? datas?.flight_no : null,
            flighTiming: datas?.flight_date ? datas?.flight_date : null,
            flightdate: datas?.flight_date ? moment(datas?.flight_date).format("DD-MM-YYYY") : null,
            flighttime: datas?.flight_date ? moment(datas?.flight_date).format("hh:mm A") : null,
            time_related:  datas?.time_related ? datas?.time_related : null,
            buffer_time: datas?.time_related ? datas?.time_related : null,
            supplier_id: oldTrip?.supplier_id ? oldTrip?.supplier_id : null,
            vehicle_type: selectedVehicle?.vehicleTypeId,
            vehicle_id: selectedVehicle?.vehicleId,
            driver_id: selectedVehicle?.driverId,
            customer_id: user?.parentDetails ? user?.parentDetails?.id : user?.id ? user?.id : null,
            created_by: user?.id ? user?.id : null,
            currency: oldTrip?.currency,
            usertype: user ? user?.role : "guest",
            stops: savedStops,
            stopCount: bookingData?.stops ? bookingData?.stops?.length : 0,
            payment_type: "card",
            arival: arrival ? arrival : null,
            booking_data: bookingData,
            selectedVehicle: seletedTrip,
            bookingType: 'PAL',
            addonItem: selectedVehicle?.extraArray?.filter(item => item?.checked),
            addonservices: selectedVehicle?.newAddOns?.filter(it => it?.checked),
            taxPer: selectedVehicle?.tax,
            vehicletype: selectedVehicle?.vehicleType,
            service_type: seletedTrip?.service_type,
            vehicle_image: seletedTrip?.vehicledetails?.vehicle_images?.length > 0 ? `${seletedTrip?.imageBasePath}${seletedTrip?.vehicledetails?.vehicle_images[0].path}` : null,
            agentMarkup: selectedVehicle?.agentmarkup,
            base_rate: selectedVehicle?.baseRate,
            markup: selectedVehicle?.adminMarkup,
            tax: selectedVehicle?.tax,
            total_price: selectedVehicle?.total,
            toll: selectedVehicle?.toll,
            extraCharges: selectedVehicle?.extras,
            addonService: addOn,
            //itenary: bookingData?.type === "hour" ? itenary?.itenary : null,
            //itenary_id: bookingData?.type === "hour" ? itenary?.id : null,
            adminDiscount: selectedVehicle?.adminDiscount,
            departure_airport_type: departure,
            departure_flight_no: datas?.departure_flight_no,
            departure_flight_date: datas?.departure_flight_date,
            offset: datas?.location_from?.offset,
            offsetdatetime: moment(moment(datas?.pickupdate).utcOffset(datas?.location_from?.offset)).utcOffset(0).format("YYYY-MM-DD HH:mm:ss"),
            extraSeat: selectedVehicle?.extraSeat,
            booking_id: oldTrip?.booking_id
        }

        if(mode === "card"){
            let payData = {
                amount: (total - priceList?.total_price) * 100,
                currency: oldTrip?.currency,
                email: user?.email ? user?.email : datas?.email
            }

            try {
                let payments = await customAxios.post('payment', payData);
                let customerId = payments?.data?.data?.customer;
                const { paymentIntent, error} = await stripe.confirmCardPayment(payments?.data.data?.client_secret, {
                    payment_method: {
                        card: elements.getElement(CardElement),
                        billing_details: {
                            name: datas?.passenger_name
                        },
                    },
                    setup_future_usage: 'off_session'
                });
    
                if (error) {
                    throw error?.message
                }
                else{
                    if (paymentIntent.status === "succeeded") {
                        let data = {
                            trip_id: oldTrip?.id,
                            trips: [trip],
                            booking_total: selectedVehicle?.total,
                            payment_type: 'online',
                            usertype: user?.role ? user?.role : null,
                            customer_id: user?.parentDetails ? user?.parentDetails?.id : user?.id ? user?.id : null,
                            created_by: user?.id ? user?.id : null,
                            currency: oldTrip?.currency,
                            transactionID: paymentIntent.id,
                            paymentStatus: paymentIntent.status,
                            code: null,
                            customer_type: user?.customer_type ? user?.customer_type : 0,
                            couponDiscount: 0,
                            base_total: selectedVehicle?.baseRate,
                            extraCharges: selectedVehicle?.extras,
                            tax: selectedVehicle?.tax,
                            addOn: addOn,
                            agentMarkup: selectedVehicle?.agentmarkup,
                            toll: selectedVehicle?.toll,
                            adminMarkup: selectedVehicle?.adminMarkup,
                            bookingType: 'PAL',
                            customer_stripe_id: customerId,
                            payment_method_id: paymentIntent?.payment_method,
                            extraSeat:  selectedVehicle?.extraSeat,
                            adminDiscount: selectedVehicle?.adminDiscount,
                        }
    
    
    
                        let tripResponse = await customAxios.post(`update-trip`, data);
    
                        if(tripResponse?.status === 200){
                            toast.success(tripResponse?.data?.message)
                            navigate('/dashboard')
                        }
                        else{
                            throw tripResponse?.data?.message
                        }
    
                        
    
                        //reactotron.log({tripResponse})
    
    
                    }
                }
            } catch (error) {
                toast.error(error)
            }
            finally{
                setLoading(false)
                setIsPaymentLoading(false)
            }
        }
        else if(mode === "wallet"){
            try {
                let data = {
                    trip_id: oldTrip?.id,
                    trips: [trip],
                    booking_total: selectedVehicle?.total,
                    payment_type: 'wallet',
                    usertype: user?.role,
                    customer_id: user?.parentDetails ? user?.parentDetails?.id : user?.id ? user?.id : null,
                    created_by: user?.id ? user?.id : null,
                    currency: oldTrip?.currency,
                    code: null,
                    customer_type: user?.customer_type,
                    couponDiscount: 0,
                    base_total: selectedVehicle?.baseRate,
                    extraCharges: selectedVehicle?.extras,
                    tax: selectedVehicle?.tax,
                    addOn: addOn,
                    agentMarkup: selectedVehicle?.agentmarkup,
                    toll: selectedVehicle?.toll,
                    adminMarkup: selectedVehicle?.adminMarkup,
                    bookingType: 'PAL',
                    customer_stripe_id: null,
                    payment_method_id: null,
                    extraSeat: selectedVehicle?.extraSeat,
                    adminDiscount: selectedVehicle?.adminDiscount,
                }
    
                let tripResponse = await customAxios.post(`update-trip`, data);
        
                if(tripResponse?.status === 200){
                    toast.success(tripResponse?.data?.message)
                    navigate('/dashboard')
                }
                else{
                    throw tripResponse?.data?.message
                }
            } catch (error) {
                toast.error(error)
            }
            finally{
                setLoading(false)
                setIsPaymentLoading(false)
            }
            
        }
        else if(mode === "credit"){
            try {
                let data = {
                    trip_id: oldTrip?.id,
                    trips: [trip],
                    booking_total: selectedVehicle?.total,
                    payment_type: 'credit',
                    usertype: user?.role,
                    customer_id: user?.parentDetails ? user?.parentDetails?.id : user?.id ? user?.id : null,
                    created_by: user?.id ? user?.id : null,
                    currency: oldTrip?.currency,
                    code: null,
                    customer_type: user?.customer_type,
                    couponDiscount: 0,
                    base_total: selectedVehicle?.baseRate,
                    extraCharges: selectedVehicle?.extras,
                    tax: selectedVehicle?.tax,
                    addOn: addOn,
                    agentMarkup: selectedVehicle?.agentmarkup,
                    toll: selectedVehicle?.toll,
                    adminMarkup: selectedVehicle?.adminMarkup,
                    bookingType: 'PAL',
                    customer_stripe_id: null,
                    payment_method_id: null,
                    extraSeat: selectedVehicle?.extraSeat,
                    adminDiscount: selectedVehicle?.adminDiscount,
                }
    
                let tripResponse = await customAxios.post(`update-trip`, data);
        
                if(tripResponse?.status === 200){
                    toast.success(tripResponse?.data?.message)
                    navigate('/dashboard')
                }
                else{
                    throw tripResponse?.data?.message
                }
            }catch (error) {
                toast.error(error)
            }
            finally{
                setLoading(false)
                setIsPaymentLoading(false)
            }
        }
            

       
    }

    if (!base) {
        return null
    }
    return (
        <>
            <BorderBox title={"Price Breakup"}>
                <Grid>
                    <Grid item>
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                            <Typography style={{ fontSize: 12 }} flex={0.5}>
                                Base Price
                            </Typography>
                            <Typography style={{ fontSize: 12 }} flex={0.5}>
                                : S${base}
                            </Typography>
                        </div>
                    </Grid>
                    {addOnItem && addOnItem?.map(addOn => (<Grid item>
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                            <Typography style={{ fontSize: 12 }} flex={0.5}>
                                {addOn?.name}
                            </Typography>
                            <Typography style={{ fontSize: 12 }} flex={0.5}>
                                : S${addOn?.price}
                            </Typography>
                        </div>
                    </Grid>))}
                    {addOnService && addOnService?.filter(addo => addo?.checked && addo?.price > 0).map(addOnSer => (<Grid item>
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                            <Typography style={{ fontSize: 12 }} flex={0.5}>
                                {addOnSer?.name}
                            </Typography>
                            <Typography style={{ fontSize: 12 }} flex={0.5}>
                                : S${addOnSer?.price}
                            </Typography>
                        </div>
                    </Grid>))}
                    {extraSeats > 0 && <Grid item>
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                            <Typography flex={0.5} style={{ fontSize: 12 }}>
                                Extra Seats
                            </Typography>
                            <Typography style={{ fontSize: 12 }} flex={0.5}>
                                : S${extraSeats}
                            </Typography>
                        </div>
                    </Grid>}
                    {toll > 0 && <Grid item>
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                            <Typography flex={0.5} style={{ fontSize: 12 }}>
                                Toll
                            </Typography>
                            <Typography style={{ fontSize: 12 }} flex={0.5}>
                                : S${toll}
                            </Typography>
                        </div>
                    </Grid>}
                    {adminDiscount > 0 && <Grid item>
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                            <Typography flex={0.5} style={{ fontSize: 12 }}>
                                Discount
                            </Typography>
                            <Typography style={{ fontSize: 12 }} flex={0.5}>
                                : -S${adminDiscount}
                            </Typography>
                        </div>
                    </Grid>}
                    {couponDiscount > 0 && <Grid item>
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                            <Typography flex={0.5} style={{ fontSize: 12 }}>
                                Coupon Discount
                            </Typography>
                            <Typography style={{ fontSize: 12 }} flex={0.5}>
                                : -S${couponDiscount}
                            </Typography>
                        </div>
                    </Grid>}
                    {tax > 0 && <Grid item>
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                            <Typography flex={0.5} style={{ fontSize: 12 }}>
                                Tax
                            </Typography>
                            <Typography style={{ fontSize: 12 }} flex={0.5}>
                                : S${tax}
                            </Typography>
                        </div>
                    </Grid>}
                    <Grid item>
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                            <Typography flex={0.5} style={{ fontSize: 12 }}>
                                Total
                            </Typography>
                            <Typography style={{ fontSize: 12 }} flex={0.5}>
                                : S${total}
                            </Typography>
                        </div>
                    </Grid>
                    <Grid item>
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                            <Typography flex={0.5} style={{ fontSize: 12 }}>
                                Paid Amount
                            </Typography>
                            <Typography style={{ fontSize: 12 }} flex={0.5}>
                                : S${priceList?.total_price}
                            </Typography>
                        </div>
                    </Grid>
                    {balanceAmount < 0 && <Grid item>
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                            <Typography flex={0.5} style={{ fontSize: 12 }}>
                                Previous Balance
                            </Typography>
                            <Typography style={{ fontSize: 12 }} flex={0.5}>
                                : S${balanceAmount}
                            </Typography>
                        </div>
                    </Grid>}
                    {(total - priceList?.total_price - balanceAmount) !== 0 && <Grid item>
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                            <Typography flex={0.5} style={{ fontSize: 12 }}>
                                Balance
                            </Typography>
                            <Typography style={{ fontSize: 12 }} flex={0.5}>
                                : S${total - priceList?.total_price + balanceAmount}
                            </Typography>
                        </div>
                    </Grid>}
                </Grid>
                <Grid item>
                {(total - priceList?.total_price + balanceAmount) <=0 && <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', marginTop: 20 }}>
                <Button variant="filled"
                    disabled={isPaymentLoading}
                    style={{ backgroundColor: isPaymentLoading ? 'gray' : '#333333', borderRadius: 4, padding: 5, height: 30 }} 
                        onClick={updatePayment}
                    >
                    <Typography className='buttonText'>{isPaymentLoading ? "Loading..." : "Update"}</Typography>
                </Button>

            </div>}
                </Grid>
            </BorderBox>
            {(total - priceList?.total_price + balanceAmount) > 0 && <BorderBox title={"Available Payment Methods"}>
                <RadioGroup
                    row
                    style={{ paddingBottom: 5, alignItems: 'center', display: 'flex', justifyContent: 'center' }}


                    sx={{
                        '& .MuiSvgIcon-root': {
                            fontSize: 20,
                        },
                    }}
                >
                    <Typography className='radioText' display={"flex"} alignItems={"center"}>
                        <FormControlLabel
                            value="card"
                            checked={mode === "card"}
                            onChange={(e) => setMode("card")}
                            control={<Radio />}
                            label={<Typography fontFamily={"Nunito"} fontSize={12}>Credit Card</Typography>}
                        />
                        {/* <img src={CreditImg} width='15px' height='15px' style={{ marginRight: 10 }} /> */}

                    </Typography>
                    {(user && (user?.role === "agent" || (user?.role === "customer" && user?.user?.walletbalance > 0))) &&
                        <Typography className='radioText' display={"flex"} alignItems={"center"}>
                            <FormControlLabel
                                disabled={user?.parentDetails?.walletbalance ? (parseFloat(user?.parentDetails?.walletbalance) < (total - priceList?.total_price - balanceAmount)) ? true : false : user?.walletbalance ? (parseFloat(user?.walletbalance) < (total - priceList?.total_price)) ? true : false : true}
                                onChange={(e) => setMode("wallet")}
                                checked={mode === "wallet" ? true: false}
                                value={"wallet"}
                                label={<div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <Typography fontFamily={"Nunito"} fontSize={12}>Wallet <span style={{ fontSize: 10 }}>(Balance {user?.parentDetails?.walletbalance ? `S$ ${parseFloat(user?.parentDetails?.walletbalance).toFixed(2)}` : user?.walletbalance ? `S$ ${parseFloat(user?.walletbalance).toFixed(2)}` : "S$ 0"})</span></Typography>

                                </div>}
                                control={<Radio />}
                            />
                            {/* <img src={WalletImg} width='15px' height='15px' style={{ marginRight: 10 }} /> */}


                        </Typography>}
                    {user && user?.role === "corporate" && <Typography className='radioText' display={"flex"} alignItems={"center"}>
                        <FormControlLabel value="credit"
                            onChange={(e) => setMode("credit")} 
                            checked={mode === "credit"}
                            control={<Radio />}
                            label={<Typography fontFamily={"Nunito"} fontSize={12}>Credit Terms</Typography>}
                        />
                        {/* <img src={CreditImg} width='15px' height='15px' style={{ marginRight: 10 }} /> */}

                    </Typography>}
                </RadioGroup>
                {mode === "card" && <CardElement
                    className="card"
                    options={{
                        hidePostalCode: true,
                        style: {
                            base: {
                                backgroundColor: "white",
                                
                            },
                            
                        },
                    }}
                />}
                {mode &&  <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', marginTop: 20 }}>
                <Button variant="filled"
                    disabled={isPaymentLoading}
                    style={{ backgroundColor: isPaymentLoading ? 'gray' : '#333333', borderRadius: 4, padding: 5, height: 30 }} 
                        onClick={makePayment}
                    >
                    <Typography className='buttonText'>{isPaymentLoading ? "Loading..." : "Pay Now"}</Typography>
                </Button>

            </div>}
            
            
            </BorderBox>}
            
        </>
    )
}

export default PriceBreakup