import React, {  useEffect } from 'react'
import './login.scss'
import { Grid, Typography, Button,  Box } from '@mui/material'
import InputField from '../../components/InputField'
import { Link, useNavigate, useNavigation } from "react-router-dom";
import GoogleImg from '../../assets/images/google.png'
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { useDispatch, useSelector } from 'react-redux'
import { googleSignIn, loginUsers } from '../../Redux/actions/userActions'
import { toast } from 'react-toastify'
import { RESET_ERROR } from '../../Redux/constants/userConstants'
import reactotron from '../../ReactronConfig'
import { useGoogleLogin } from '@react-oauth/google';
import HomeIcon from '@mui/icons-material/Home';
import axios from 'axios'
import { display } from '@mui/system';
import Avatar from '@mui/material/Avatar/Avatar';
import logo from '../../assets/images/pal_logo.png'


const Login = () => {

	const dispatch = useDispatch();
 
	

	const login = useGoogleLogin({
		onSuccess: tokenResponse => {
			getUserProfile(tokenResponse.access_token)
		},
	});


	const getUserProfile = async(token) => {
		await axios
		.get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${token}`, {
			headers: {
				Authorization: `Bearer ${token}`,
				Accept: 'application/json'
			}
		})
		.then((res) => {
			//setProfile(res.data);


			let data = {
				email: res?.data?.email,
				name: res?.data?.name
			}
			localStorage.setItem("loginType", "google");
			dispatch(googleSignIn(data))

		})
		.catch((err) => toast.error({err}));
	}

	const { loading, isAuthenticated, error } = useSelector(state => state.auth)

	const schema = yup.object({
		email: yup.string().email('Invalid email format').required('Email required').max(30,'Maximum character exceeded'),
		password: yup.string()
			.required('No password provided.')
			.min(8, 'Password should contain at least 8 characters').max(30,"Maximum character exceeded")
	}).required();

	const navigate = useNavigate();
	const { register, handleSubmit, control, formState: { errors } } = useForm({
		resolver: yupResolver(schema),
		mode:'onChange'
	});

	useEffect(() => {
		if (error) {
			toast.error(error)
			dispatch({
				type: RESET_ERROR
			})
		}
		if (isAuthenticated) {
			navigate("/dashboard")
		}
	}, [error, isAuthenticated])


	const onSubmit = data => {
		localStorage.setItem("loginType", "form");
		dispatch(loginUsers(data))
	};

	

	return (
		<div style={{position:'relative'}}>
			 {/* <div style={{position:'absolute',top:10,left:5,background:'rgba(0,0,0,0.5)', display:'flex',alignItems:'center'}}><HomeIcon style={{fontSize:30,cursor:'pointer',color:'#fff'}}  onClick={()=>navigate('/')}/></div> */}
           
			<Grid container>
				<Grid xs={12} md={6} sx={{ flexDirection: 'row', justifyContent: 'start', alignItems: 'center',cursor:'pointer' }} display={{ xs: "none", md: "block" }} className=" loginBackground"  onClick={()=>navigate('/')} >

				</Grid>
				<Grid xs={12} md={6} className="login" position={'relative'} >
				     <Grid position={'absolute'} width={'100%'} height={50} boxShadow={.5} justifyContent={'center'} top={0} display={{ md:'none',xs:'flex'}} alignItems={'center'}  px={2}>
						<Avatar src={logo} variant="square" sx={{width:60,height:30,cursor:'pointer'}} onClick={()=>navigate('/')}>

						</Avatar>
					 </Grid>
					<form onSubmit={handleSubmit(onSubmit)}>
						<div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'start', alignItems: 'center' }}>
							<h2 className='loginheading'>LOGIN</h2>
							<Box width={{ xs: "95%", md: '50%' }}>
							<InputField
								fieldLabel="Email Address"
								fieldName="email"
								control={control}
								error={errors.email}
								width={{ xs: "95%", md: '50%' }}
							/>
							<div style={{ paddingTop: 5 }}>
							<InputField
								fieldLabel="Password"
								fieldName="password"
								control={control}
								error={errors.password}
								width={{ xs: "95%", md: '50%' }}
								fieldType={"password"}
							/>
							</div>
							</Box>
							
						</div>
						<Box width={{ xs: "95%", md: '75%' }} display={"flex"} justifyContent="flex-end" paddingTop={"1px"} paddingBottom={1}>
							<Typography onClick={() => navigate("/forgotpassword")} style={{ fontFamily: 'Nunito', cursor: 'pointer', fontSize: 12, marginRight: 10,  }}>FORGOT PASSWORD?</Typography>
						</Box>
						<Grid  item xs={12} md={12}  style={{ display: 'flex',  justifyContent: 'center', gap:5, paddingTop: 5 }}>
							<Button  variant="filled" onClick={() => login()}  style={{ backgroundColor: '#4286F5', borderRadius: 4, height: 30, width: 132, padding: 0, margin: 0, paddingLeft: 0.4 }}
								startIcon={<img src={GoogleImg} style={{width: 22, height: 22, margin: 0, paddingLeft: 2 }} />}>
								<Typography className='buttonText'> Google Sign In</Typography>
							</Button>

							<Button type="submit"
								sx={{ boxShadow: 2 }}
								variant="filled" style={{ backgroundColor: '#333333', borderRadius: 4, width: 132, height: 30 }}
							>
								<Typography className='buttonText'>Login</Typography>
							</Button>						
						</Grid>
						<Grid px={3} style={{ display: 'flex', flexDirection: 'row', paddingTop: "1%", justifyContent: 'center', alignItems: 'center' }}>
							<span style={{ fontFamily: 'Nunito', fontSize: 13, marginRight: 10 }}>Don't have an account yet ? Register as  <Link to={"/b2bregistration"} className='accountText' style={{ cursor: 'pointer', fontFamily: 'Nunito' }}>Company</Link> or <Link to={"/customerregistration"} className='accountText' style={{ cursor: 'pointer', fontFamily: 'Nunito' }}>Customer</Link></span>
						</Grid>
					</form>
				</Grid>
			</Grid>
		</div>
	)
}

export default Login
