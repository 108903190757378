import React, { useState, useEffect } from 'react'
import BorderBox from '../../components/BorderBox'
import { Box, Grid, FormControlLabel, Checkbox, Typography } from '@mui/material'
import reactotron from '../../ReactronConfig'

const AddOnServices = ({ selectedVehicle, locationTo, serviceChanged }) => {

    reactotron.log({selectedVehicle})

    const [services, setServices] = useState([])

    useEffect(() => {
        setServices(selectedVehicle?.newAddOns)
    }, [selectedVehicle])

    const serviceChange = (checked, id) => {
        setServices(prev => {
            let previ = prev?.map(pre => {
                if(pre.id === id){
                    pre.checked = checked;
                    return pre;
                }
                else{
                    return pre;
                }
            })
            serviceChanged(previ)
            return previ;
        })
    }

    if(!services){
        return null
    }
    

    return (
        <BorderBox title={"AddOn Services"}>
            <Grid container display={"flex"}  >
                {services?.map((serv, index) => (
                    <Grid xs={12} sm={6} md={4} lg={6} xl={3} key={index} spacing={0.5}>
                        <FormControlLabel
                            style={{ fontSize: "12px" }}

                            control={
                                <Checkbox 
                                    style={{ fontSize: 12 }} 
                                    checked={serv?.checked} 
                                    onChange={(e) => serviceChange(e.target.checked, serv?.id)} 
                                    name={serv?.id} 
                                />
                            }
                            label={<Typography style={{ fontSize: 12 }}>{serv?.name} (S${serv?.price})</Typography>}
                        />
                    </Grid>
                ))}
                {locationTo?.type === "airport" && <Grid xs={12} sm={6} md={4} lg={6} xl={3}>
                    <FormControlLabel
                        style={{ fontSize: "12px" }}
                        control={
                            <Checkbox style={{ fontSize: 12 }} checked={true} disabled name={"backageProtection"} />
                        }
                        label={<Typography style={{ fontSize: 12 }}>Baggage Protection</Typography>}
                    />
                </Grid>}
            </Grid>
        </BorderBox>
    )
}

export default AddOnServices