import axios from "axios";
import { env } from "../config/Constants"
import reactotron from "../ReactronConfig";
import customAxios from "../CustomAxios";

export async function getAddress(place){
    reactotron.log({place})
    if(place?.place_id){
        return {
            ...place,
            description: place?.address
        };
    }
    else{
        let res = await axios.get(`https://maps.googleapis.com/maps/api/geocode/json?address=${place?.latitude},${place?.longitude}&key=${env === "dev" ? process.env.REACT_APP_GOOGLE_KEY_DEV : process.env.REACT_APP_GOOGLE_KEY_LIVE}`)
        reactotron.log({place: res?.data?.results})
        if(res?.status === 200 && res?.data?.status === "OK"){
            let data = {
                ...place,
                place_id:res?.data?.results?.[0]?.place_id,
                description: place?.address,
                offset: place?.utc_offset,
            }
    
            return data;
        }
    }
   
}

export async function getDirection(fromPlaceId, toPlaceId){
    let datas = {
        fromPlaceid: fromPlaceId,
        toPlaceid: toPlaceId
    }
    let res = await customAxios.post(`getdurations`, datas);

    reactotron.log({res})

    let data = res?.data?.data?.rows?.[0]?.elements?.[0];
    return data;
}