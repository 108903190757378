import React, { Fragment, useEffect } from 'react'
import WorkFlow from '../../components/WorkFlow'
import Layout from '../Layout'
import Banner from '../../components/Banner'
import './style.scss'
import TripHistory from './TripHistory'
import { Button, Grid, Stack } from '@mui/material'
import DriveEtaIcon from '@mui/icons-material/DriveEta';
import TopBar from '../../components/TopBar'
import { useNavigate } from "react-router-dom";
import TopMenu from '../../components/TopMenu'
import { useDispatch, useSelector } from 'react-redux'
import { getTrip } from '../../Redux/actions/tripActions'
import CarImg from '../../assets/images/IconsNew/car.png'
import { AddCircle } from '@mui/icons-material'
import { RESET_BOOKING } from '../../Redux/constants/bookingConstants';
const Dashboard = ({ head }) => {
    const navigate = useNavigate();

    const dispatch = useDispatch()

    const { user } = useSelector(state => state.auth)

    useEffect(() => {
        let params = {
            parent_id : user?.parentDetails ? user?.parentDetails?.id : user?.id,
            child_id : user?.id
        }
        
        //let id = user?.id
        dispatch(getTrip(params))
    }, [])

    useEffect(() => {
        dispatch({
            type: RESET_BOOKING
        })
      }, []);


    return (
        <Layout head={head}>
            {/* <TopBar activeMenu="1"/> 
            <TopMenu /> */}

            <Stack direction={"row"} justifyContent="space-between" alignItems={"center"} >
                <h1 class='underline'><span style={{ textDecorationLine:'underline', textDecorationColor:'#B6A269' }}>Trip </span>History</h1>
                {/* <Grid display={{ xs: 'none', md: 'flex' }} style={{ flexDirection: 'row', justifyContent: 'center' }} >
                    <Button 
                        variant="contained" 
                        endIcon={<img src={CarImg} style={{width:25,height:25}} />}
                        style={{ color: 'white', backgroundColor: '#333333', borderRadius: 1, height: 40 }}
                        onClick={() => navigate("/newbooking")} 
                        className="newbooking" 
                    >
                        NEW BOOKING
                    </Button>
                    
                </Grid> */}
                <Grid display={{ xs: 'flex', md:'none' }}>
                    <AddCircle onClick={() => navigate("/newbooking")}  />
                </Grid>
            </Stack>
            <TripHistory />
        </Layout>
    )
}

export default Dashboard
