import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Divider, Stack } from '@mui/material'
import { DirectionsRenderer, DirectionsService, GoogleMap } from '@react-google-maps/api'
import React, { useState } from 'react'

const ViewMap = ({ stops, setStops, data }) => {

    const [load, setLoad] = useState(false)

    const [distance, setDistance] = useState('')
    const [duration, setDuration] = useState('')
    const [directions, setDirections] = useState('');

    const containerStyle = {
        width: '30vw',
        borderBottomLeftRadius: '45px',
        borderBottomRightRadius: '45px',
        height: '300px',
    };

    const directionsCallback = (response) => {
        

        if (response !== null) {
            if (response.status === 'OK') {
                if (!directions || data?.stops.length !== stops) {
                    setStops(data?.stops?.length)
                    setDirections(response)
                }

                let distance = 0, duration = 0;
                response.routes[0].legs.map(leg => {
                    distance += leg.distance.value
                    duration += leg.duration.value
                })
                setDistance(`${(distance / 1000).toFixed(1)} km`);
                setDuration(`${(duration / 60).toFixed(1)} min`)
            } else {
                
            }
        }
    }

    return (
        <Accordion style={{ paddingBottom: 0, boxShadow: 'none', border: '0px', backgroundColor: 'transparent' }} >
            <AccordionSummary >
                <Stack display={"flex"} flexDirection={"row-reverse"} flex={1}>
                    <Button
                        onClick={() => setLoad(!load)}
                        variant="contained" justifyContent='center'
                        style={{ color: '#fff', backgroundColor: '#B6A269', borderRadius: 20, height: 20, width: 100,  zIndex: 2 }} >

                        <h5 color='#fff' >View Map</h5>
                    </Button>
                </Stack>
                {/* <Box width={{ xs: '50%', md: '100%' }}>
                    <Divider orientation="horizontal" sx={{ bgcolor: "#B6A269", height: '2px', marginTop: 2, width: '100%', marginLeft: -5 }} />
                </Box> */}
            </AccordionSummary>

            <AccordionDetails sx={{ position: 'relative', borderRadius: '15px', display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: 100 }}>

                <GoogleMap
                    mapContainerStyle={containerStyle}
                    zoom={0}
                    clickableIcons={false}
                    options={{
                        scrollwheel: false,
                        disableDoubleClickZoom: true,
                        zoomControl: false,
                        gestureHandling: 'none',
                        fullscreenControl: false,
                        streetView: null,
                        streetViewControl: false,
                        mapTypeControl: false,
                        disableDefaultUI: true,
                        styles: [{ elementType: "labels", featureType: "poi.business", stylers: [{ visibility: "off", }], }]
                    }}
                >
                    {load && <>
                        <DirectionsService
                            options={{
                                destination: data?.location_to?.address,
                                origin: data?.location_from?.address,
                                waypoints: data?.waypoints,
                                optimizeWaypoints: false,
                                travelMode: 'DRIVING',
                            }}
                            callback={directionsCallback}
                            onLoad={directionsService => {

                            }}
                            onUnmount={directionsService => {

                            }}
                        />
                        {directions && <DirectionsRenderer
                            // required
                            options={{
                                directions: directions
                            }}
                            // optional
                            onLoad={directionsRenderer => {
                                // console.log('DirectionsRenderer onLoad directionsRenderer: ', directionsRenderer)
                            }}
                            // optional
                            onUnmount={directionsRenderer => {
                                // console.log('DirectionsRenderer onUnmount directionsRenderer: ', directionsRenderer)
                            }}
                        />}
                    </>}
                </GoogleMap>
            </AccordionDetails>
        </Accordion>
    )
}

export default ViewMap