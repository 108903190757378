import React from 'react'
import './style.scss'
import Person from '@mui/icons-material/Person';
import FacebookRoundedIcon from '@mui/icons-material/FacebookRounded';
import instaLogo from '../assets/images/instagram.jpg'
import Divider from '@mui/material/Divider'
import RegisterMenu from './RegisterMenu';
import { useNavigate } from "react-router-dom";

const HomeHeader = () => {
    const navigate = useNavigate();
    return (
            <div style={{ display: 'flex',height:'auto' ,flexDirection:'row' ,backgroundColor:'#333333', justifyContent:'end',alignItems:'center', paddingLeft: '3%', paddingRight: '5%'}}>
                <Person sx={{ display: { xs: 'flex', md: 'flex' }, mr: 1 }} style={{color:'white'}}/>
                <span className='headerstyle'style={{marginRight:10,bottom:10}} onClick={() => navigate("/signin")}>
                    LOGIN
                </span>
                <Divider orientation="vertical" color='white' variant="middle" flexItem/>
                <span className='headerstyle' style={{marginRight:30,marginLeft:10}}>
                    <RegisterMenu menuname={"REGISTER"}/>
                </span>
                <FacebookRoundedIcon sx={{ display: { xs: 'flex', md: 'flex' }, mr: 1 }} style={{color:'white'}}/>
                <img src={instaLogo} style={{ width: 25, height: 25,marginRight:50 }} />  
            </div>       
    )
}

export default HomeHeader

