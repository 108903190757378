import React, { useEffect } from 'react'
import { Autocomplete, TextField, Typography, FormGroup } from '@mui/material';
import { useDebounce } from 'use-debounce';
import { makeStyles, styled, withStyles } from '@mui/styles';
import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import { Controller } from "react-hook-form";
import { isArray } from 'lodash';




const useStyles = makeStyles(() => ({
    noBorder: {
        borderRadius: 0,
        border: 0,
    },
    resize: {
        fontSize: 12,
        paddingLeft: 2
    }
}));



const GooglePlaceInput = ({ label, location, setLocation, options, control, fieldName, error, suggList }) => {

    const classes = useStyles();

    const [search, setSearch] = React.useState(null);

    const [value] = useDebounce(search, 1000);
    const [place, setPlace] = React.useState(null);

    const {
        placesService,
        placePredictions,
        getPlacePredictions,
        isPlacePredictionsLoading,
    } = usePlacesService({
        apiKey: process.env.REACT_APP_GOOGLE_KEY
    });


    console.log(placePredictions)


    useEffect(() => {
        getPlacePredictions({ input: value, types: ['airport', 'political', 'postal_code', 'point_of_interest'] });
    }, [value])

    const placeSelect = (place) => {

        if (place?.place_id) {
            placesService?.getDetails(
                {
                    placeId: place?.place_id,
                },
                (placeDetails) => {
                    console.log({ placeDetails })
                    let index = placeDetails?.address_components?.map((res) => res).map((res) => res?.types.includes('country')).indexOf(true)
                    let result = placeDetails?.address_components?.[index]

                    //console.log({time: moment(new Date()).utcOffset(0).format("hh:mm A")})
                    let details = {
                        address: place?.description,
                        latitude: placeDetails.geometry.location.lat(),
                        longitude: placeDetails.geometry.location.lng(),
                        type: placeDetails.types.includes('airport') ? 'airport' : 'point',
                        countryCode: result?.short_name,
                        countryName: result?.long_name,
                        utcOffset: placeDetails?.utc_offset_minutes,
                        place_id: place?.place_id,
                        description: place?.description,
                        offset: placeDetails?.utc_offset_minutes,
                    }
                    setLocation(details)
                    setPlace(details)
                }
            );
        }
    }




    return (
        <>
        <FormGroup style={{ marginRight: 0, marginLeft: 5, marginBottom: 1 }}>
            <Typography sx={{ fontSize: '12px' }}>{label}</Typography>
            <Controller
                name={fieldName}
                control={control}
                render={({ field: { onChange, onBlur, value } }) => {
                    return (
                        <Autocomplete
                            disablePortal
                            fullWidth
                            options={ (isArray(placePredictions) && placePredictions?.length > 0) ? placePredictions : suggList ? suggList : []}
                            value={value ? value : null}
                            onChange={(event, newValue) => {
                                placeSelect(newValue)
                            }}
                            getOptionLabel={(item) => item?.description ? item?.description : ''}
                            sx={{
                                // border: "1px solid blue",
                                "& .MuiOutlinedInput-root": {
                                    // border: "1px solid yellow",
                                    borderRadius: "0",
                                    padding: "0"
                                },
                                "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                                    border: "0px solid #eee"
                                }
                            }}
                            renderOption={(props, option) => (
                                <Typography key={option?.place_id} {...props} style={{ cursor: 'pointer', fontSize: 12, fontWeight: 600 }} >{option?.description}</Typography>
                            )}
                            popupIcon={null}
                            renderInput={(params) => <TextField
                                placeholder='Search'
                                fullWidth
                                required
                                variant='standard'
                                onChange={(evt) => {
                                    setSearch(evt.target.value);
                                }}

                                {...params}
                                InputProps={{
                                    ...params.InputProps,
                                    disableUnderline: true,
                                    classes: {
                                        input: classes.resize,
                                        
                                    }
                                }}
                                style={{ backgroundColor: '#fff', paddingLeft: 5, border: '1px solid #ccc', borderRadius: 4, width: '98%', height: 30, justifyContent: 'center' }}
                            />}


                        />
                    )
                }}
            />
        </FormGroup>
        {error && <Typography color={"red"} ml={3} fontSize={10} mt={0}>{error}</Typography>}
        </>
    )
}

export default GooglePlaceInput
