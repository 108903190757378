import React, { useState } from 'react'
import { Grid, Modal, Typography, Button, Box, FormGroup, IconButton, FormControlLabel, Switch, CircularProgress } from '@mui/material'
import InputField from '../../components/InputField'
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch, useSelector } from 'react-redux';
import { updateSubUserProfile } from '../../Redux/actions/profileActions';
import BorderBox from '../../components/BorderBox';
import PhoneInputs from '../../components/PhoneInputs';

const EditUser = ({ openEditModal, closeEditModal, users }) => {

    console.log({users})

    const dispatch = useDispatch()

    const [active, setActive] = useState(users?.is_active === 1 ? true : false)

    const { user } = useSelector(state => state.auth)
    const { loading } = useSelector(state => state.profile)
    const [country, setCountry] = useState({
        callingCode: users?.country_code,
        phonenumber: users?.phone
      });

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: 'background.paper',
        borderRadius: '25px',
        border: '1px solid #fff',
        p: 4,
        height: '671',
        width: '35%'
    };
    const schema = yup.object({
        name: yup.string().required('Name required'),
        phone: yup.string().required('Phone Number required'),
    }).required();

    const { register, handleSubmit, control, formState: { errors }, setValue, reset } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            ...users,
            phone: `${users.country_code?.replace('+', '')}${users.phone}`
        }
    });
    const onSubmit = (data) => {
  
        //dispatch(addTopUp(formData))
        let datas = {
            ...users,
            name: data?.name,
            country_code : country?.callingCode,
            phone: country?.phonenumber,
            is_active: active ? 1 : 0,
            userID: users?.id
        }

        let userData = {
            "parent_id": user?.id
        }

        dispatch(updateSubUserProfile(datas, userData))

    };


    const changePhone = (value) => {
        setCountry(value)
    }


    return (

        <Modal open={openEditModal} onClose={closeEditModal}  >
            <Box sx={style} >
                <div style={{ display: 'flex', justifyContent: 'flex-end', padding: '1%' }}>
                    <IconButton onClick={closeEditModal} style={{ alignSelf: 'flex-end', flex: 0.05 }}>
                        <CloseIcon />
                    </IconButton>
                </div>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <BorderBox title="Edit User">
                            <Grid container spacing={0.5} alignItems="center">
                                <Grid item xs={12} md={6}>
                                    <InputField
                                        fieldLabel="Name"
                                        fieldName="name"
                                        control={control}
                                        error={errors.name}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <InputField
                                        fieldLabel="Email"
                                        fieldName="email"
                                        control={control}
                                        error={errors.email}
                                        readonly
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <PhoneInputs
                                        fieldLabel="Phone"
                                        fieldName="phone"
                                        control={control}
                                        error={errors.phone}
                                        onChangePhone={changePhone}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6} alignItems="center">
                                    <FormControlLabel control={<Switch checked={active} onChange={() => setActive(!active)} />} label="Status" style={{ marginTop: 20 }} />
                                </Grid>
                            </Grid>

                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', paddingTop: 20 }}>
                            </div>
                    </BorderBox>
                    <div style={{ display: 'flex', justifyContent: 'center', marginTop: 10 }}>
                        <Button
                            type="submit"
                            disabled={loading}
                            variant="filled"
                            style={{ backgroundColor: '#333333', borderRadius: 4, height: 30, padding:5 }}
                            onClick={handleSubmit(onSubmit)}
                        >
                            <Typography className='buttonText'>{loading ? <CircularProgress /> : 'UPDATE'}</Typography>
                        </Button>
                    </div>
                </form>
            </Box>

        </Modal>
    )
}

export default EditUser
