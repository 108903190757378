
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Navigate, Redirect, Route, useNavigate } from 'react-router-dom'



function PrivateRoute({ children }) {

  
  
  //const auth = useAuth();
  const { isAuthenticated, previousRoute } = useSelector(state => state.auth )
  return isAuthenticated ? previousRoute ? <Navigate to={previousRoute} /> : children : <Navigate to="/signin" />;
}

export default PrivateRoute
// This is used to determine if a user is authenticated and
// if they are allowed to visit the page they navigated to.

// If they are: they proceed to the page
// If not: they are redirected to the login page.
// import { Navigate, Redirect, Route, useNavigate } from 'react-router-dom'
// import { useSelector } from 'react-redux'
// import React from 'react'
// import reactotron from './ReactronConfig'

// const PrivateRoute = ({ component: Component, ...rest }) => {
//   //const navigate = useNavigate();
//   const { isAuthenticated } = useSelector(state => state.auth )



//   if(isAuthenticated){
//     return (
//       <Route
//         {...rest}
//         render={props =>
//           isAuthenticated ? (
//             <Component {...props} />
//           ) : (
//             <Navigate to={{ pathname: '/signin', state: { from: props.location } }} />
//           )
//         }
//       />
//     )
//   }
//   else{
//     const user = localStorage.getItem("user");
//     if(user){
//       return (
//         <Route
//           {...rest}
//           render={props =>(
//               <Component {...props} />
//             ) 
//           }
//         />
//       )
//     }
//     else{
//       return (
//         <Route
//           {...rest}
//           render={props =>(
//               <Navigate to={{ pathname: '/signin', state: { from: props.location } }} />
//             )
//           }
//         />
//       )
//     }
//   }
// }

// export default PrivateRoute