import React, { useState, useEffect } from 'react'
import Layout from '../Layout'
import Banner from '../../components/Banner'
//import './style.scss'
import { Button, Grid, Typography, FormGroup, Alert, Snackbar, Stack, MenuItem, Box } from '@mui/material'
import DriveEtaIcon from '@mui/icons-material/DriveEta';
import ErrorIcon from '@mui/icons-material/Error';
import InputField from '../../components/InputField'
import { fontWeight } from '@mui/system'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import TopBar from '../../components/TopBar'
import BackupIcon from '@mui/icons-material/Backup';
import InputIcon from '../../components/InputIcon';
import { addMarkup, getMarkup } from '../../Redux/actions/markupActions';
import { useSelector, useDispatch } from 'react-redux'
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { RESET_ERROR } from '../../Redux/constants/markupConstants';
import DropdownInput from '../../components/DropdownInput';
import BorderBox from '../../components/BorderBox';

const MarkUp = ({ head }) => {
    const dispatch = useDispatch();
    const [open, setOpen] = React.useState(false);
    const [currentValue, setCurrentValue] = React.useState('');
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };
    const { user } = useSelector(state => state.auth)
    //const user= localStorage.getItem('user')
    useEffect(() => {
        let id = user?.parentDetails ? user?.parentDetails?.id : user?.id
        dispatch(getMarkup(id))
    }, [])
    const { newMarkup, error, markupLists } = useSelector(state => state.markup)
    useEffect(() => {
        if (error) {

            dispatch({
                type: RESET_ERROR
            })
        }

        if (newMarkup) {
            dispatch({
                type: RESET_ERROR
            })
            let id = user?.parentDetails ? user?.parentDetails?.id : user?.id
            dispatch(getMarkup(id))
            setOpen(true)
        }

    }, [error, newMarkup])

    const schema = yup.object({
        type: yup.string().required('Type required'),
        value: yup.string().required('Amount required'),
    }).required();

    const { register, handleSubmit, control, formState: { errors }, setValue, getValues } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            type: markupLists?.type
        }
    });


    useEffect(() => {
        if (markupLists) {
            setCurrentValue(markupLists.type === "fixed" ? `s$ ${markupLists.value}` : `${markupLists.value}%`)

            setValue("type", markupLists?.type);
            setValue("value", parseFloat(markupLists?.value).toFixed(2))

        }
    }, [markupLists])


 
    const onSubmit = (data) => {
    
        let formData = new FormData();
        formData.append("user_id", user.id);
        formData.append("value", data.value);
        formData.append("type", data.type);
       

        dispatch(addMarkup(formData))
    };

    return (
        <Layout head={head}>
            <Snackbar open={open} autoHideDuration={2000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
                    Markup added Successfully
                </Alert>
            </Snackbar>
            {/* <TopBar activeMenu="5"/>  */}


            {/* <Stack flexDirection={"row"} justifyContent="space-between" alignItems={"center"}>

                {markupLists &&
                 <Grid style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', paddingTop: '4%' }}  >
                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'start' }}>
                        <Typography fontSize='25px' fontFamily='Nunito' fontWeight="bold">Current Value</Typography>
                        <Stack  flexDirection="row" style={{ color: '#333333', backgroundColor: 'rgb(255,195,0,0.3)', borderRadius: 27, height: 70, fontSize: '40px', fontFamily: 'Tiempos Headline', fontWeight: 'bold', alignItems: 'center' }}>
                            <Stack style={{ color: '#333333', 
                            backgroundColor: '#FEF7DF', borderRadius: 27, height: 70, fontSize: '40px', width: 70, fontFamily: 'Tiempos Headline', fontWeight: 'bold', justifyContent:'center', alignItems:'center' }}>
                                {markupLists?.type === "fixed" ? 'S$' : '%'}
                            </Stack>
                            <Stack justifyContent={"center"} marginLeft={1}>
                            {parseFloat(markupLists?.value).toFixed(2)}
                            </Stack>
                        </Stack>
                    </div>
                </Grid>}
            </Stack> */}
            {markupLists && <Stack pt={1} direction={'row'} gap={1}>
                <Typography fontSize='20px' fontFamily='Nunito' fontWeight="bold">Current Value</Typography>
                <Typography fontSize='20px' fontFamily='Nunito' fontWeight="bold">:</Typography>
                {markupLists?.type === 'fixed' ? <Typography fontSize='20px' fontFamily='Nunito' fontWeight="bold">
                    {markupLists?.type === "fixed" && 'S$'}{parseFloat(markupLists?.value).toFixed(2)}</Typography>
                    : <Typography fontSize='20px' fontFamily='Nunito' fontWeight="bold">
                        {parseFloat(markupLists?.value).toFixed(2)}{'%'}</Typography>
                }
            </Stack>}
            <Stack justifyContent={"center"} alignItems="center" paddingTop={ markupLists ? 1 : 4}>
                <Grid style={{ width: "30%", justifyContent: 'center', alignItems: 'center' }}>
                    <form onSubmit={handleSubmit(onSubmit)} >
                        <BorderBox title="Amount Details">
                            <Grid container >
                                <Grid xs={12} md={6} sx={{ flexGrow: 1 }}>
                                    <DropdownInput
                                        fieldLabel="Type"
                                        fieldName="type"
                                        control={control}
                                        error={errors.type}
                                    >
                                        <MenuItem value='fixed'>Fixed</MenuItem>
                                        <MenuItem value='Percentage'>Percentage</MenuItem>
                                    </DropdownInput>
                                </Grid>
                                <Grid xs={12} md={6} sx={{ flexGrow: 1 }}>
                                    <InputField
                                        fieldLabel="Amount"
                                        fieldName="value"
                                        control={control}
                                        error={errors.value}
                                    />
                                </Grid>

                            </Grid>
                        </BorderBox>
                        <Grid xs={12} md={12} sx={{ flexGrow: 1 }}>
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', paddingTop: '2%', paddingBottom: '15%' }}>
                                <Button type="submit" variant="filled" style={{ backgroundColor: '#333333', borderRadius: 4, padding: 5, height: 30 }}>
                                    <Typography className='buttonText'>CONFIRM</Typography></Button>
                            </div>
                        </Grid>
                    </form>
                </Grid>
            </Stack>

        </Layout>
    )
}

export default MarkUp
