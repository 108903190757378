import React, { useRef, useState } from 'react'
import { FormGroup } from '@mui/material';
import { TextField, Typography, InputAdornment } from '@mui/material'
import { Controller } from "react-hook-form";
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
	resize: {
		fontSize: 12,
		lineHeight: 1.5
	},
}));

const ImageInput = ({ fieldName, fieldLabel, width, height, fontSize, control = { control }, Icon, changeImage, error: receiptError, type }) => {
	const [error, setError] = useState('')
	const classes = useStyles();
	const inputFile = useRef(null);

	const [imageName, setImageName] = useState(null)

	let format = type ? type : "image/*"

	const validateImage = (e) => {
		setError('')
	
		changeImage(e.target.files[0])
		if (type) {
			setImageName(e.target.files[0].name)
			changeImage(e.target.files[0])
			console.log("TYPE FILE FORMAT")


		} else {
			if (e.target.files[0].size > 2000000) {

				setError('Selected Image exceeds file size limit. Please select image below 2Mb')
				changeImage(null)
			}
			else {
				let img = new Image()
				img.src = URL.createObjectURL(e.target.files[0])
				changeImage(e.target.files[0])

				setImageName(e.target.files[0].name)
			}

		}

		//changeFiles(e.target.files[0]);
	}


	return (
		<>
			<FormGroup style={{ marginRight: 5, marginLeft: 5, marginBottom: 0, minWidth: 150 }}>
				<Typography className='formText' sx={{ fontSize: '12px' }} >{fieldLabel}</Typography>
				<Controller
					name={fieldName}
					control={control}
					render={({ field: { onChange, onBlur, value } }) =>
						<div>
							<TextField
								ref={inputFile}
								error={error ? true : false}
								style={{ backgroundColor: '#fff', justifyContent: 'center', border: '1px solid #ccc', borderRadius: 4, paddingLeft: 5, height: 30, cursor: 'pointer', width: '98.5%' }}
								onBlur={onBlur}
								variant='standard' className='formText'
								sx={{
									"& .MuiFilledInput-underline:before": {
										borderBottom: "none",

									},
									"& .MuiFilledInput-underline:hover:not(.Mui-disabled):before": {
										borderBottom: "none",
									},
									"& .MuiFilledInput-root": {
										paddingRight: "0px",
									},
									borderRadius: '10px',
									input: { cursor: 'pointer' }
								}}
								accept="image/*"
								onClick={() => inputFile.current.click()}
								value={imageName}
								onChange={(e) => {
									validateImage(type ? e :  e, width, height)
								}}
								inputProps={{
									accept: type ? type : 'image/*',

								}}
								InputProps={{
									classes: {
										input: classes.resize,
									},
									height: 30,
									readOnly: true,
									borderRadius: 0,
									fontSize: 12,
									endAdornment: (
										<InputAdornment style={{ cursor: 'pointer' }} >
											{Icon}
										</InputAdornment>
									),
									disableUnderline: true
								}}
							/>
							<input type={"file"} accept={format} style={{ display: 'none' }} ref={inputFile} 
							onChange={(e) => {
								validateImage(type ? e :  e, width, height)
							}} />

						</div>}
				/>
			</FormGroup>
			<Typography fontSize={10} color="red">{error || receiptError?.message}</Typography>
		</>
	)
}

export default ImageInput
