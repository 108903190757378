import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import './style.scss'
import Person from '@mui/icons-material/Person';
import FacebookRoundedIcon from '@mui/icons-material/FacebookRounded';
import mockLogo from '../assets/images/Logo_mockup.jpg'
import Divider from '@mui/material/Divider'
import { useLocation, useNavigate } from 'react-router-dom'
import { SET_PREVIOUS_ROUTE } from '../Redux/constants/userConstants'
import { Stack } from '@mui/system';
import { Avatar } from '@mui/material'
import RegisterMenu from './RegisterMenu';

const Header = () => {
    
    const { user } = useSelector(state => state.auth)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const location = useLocation();
    const loginUser = () => {
      
        dispatch({
            type: SET_PREVIOUS_ROUTE,
            payload: location.pathname
        })

        navigate('/signin')
    }

    if (user) {
        return (
            <Stack px={{ xs: 1, sm: 5, md: 5, lg: 7, xl: 12 }} style={{ display: 'flex', height: 'auto', flexDirection: 'row', backgroundColor: '#333333', justifyContent: 'end', alignItems: 'center', height: 40 }} paddingRight={{ xs: 5, md: 7, lg: 9, xl: 10 }}>
                <Avatar src={user?.image} />
                <span className='headerstyle' style={{ marginRight: 30, marginLeft: 10 }}>{user?.name}</span>
            </Stack>
        )
    }

    return (
        <div
            style={{
                display: 'flex',
                height: 'auto',
                flexDirection: 'row',
                backgroundColor: '#333333',
                justifyContent: 'end',
                alignItems: 'center',
                paddingRight: '1%'
            }}
        >
            <Person sx={{ display: { xs: 'flex', md: 'flex' }, mr: 1 }} style={{ color: 'white' }} />
            <span onClick={() => navigate("/signin")} className='headerstyle' style={{ marginRight: 10, bottom: 10 }} >
                LOGIN
            </span>
            <Divider orientation="vertical" color='white' variant="middle" flexItem />
            <span className='headerstyle' style={{ marginRight: 10, marginLeft: 10 }}>
                <RegisterMenu menuname={"REGISTER"}/>
            </span>
            <FacebookRoundedIcon sx={{ display: { xs: 'flex', md: 'flex' }, mr: 1 }} style={{ color: 'white' }} />
        </div>
    )

}

export default Header

