//export const API_URL = 'https://pal.diginestsolutions.in/public/api';
//export const API_URL = 'https://192.168.1.54/public/api/';

// 1- corporate, 2-ta, 3-customer, 4 -supplier

export const env = "dev"

const URLS = {
    client: "https://pal.otademo.com/",
    live: "https://api.fast2fastpay.com/",
    dev: "http://18.220.19.226:3010/",
    metro: "https://metroadmin.dnsappdemo.com/",
}

const url = {
    client:"https://pal.otademo.com/public/api",
    dev: "https://pal.diginestsolutions.in/public/api",
    live: "https://paladmin.dnsappdemo.com/public/api",
    metro: "https://metroadmin.dnsappdemo.com/public/api",
}

const viewurl = {
    client:"https://pal.otademo.com/public/auth/",  
    dev: "https://pal.diginestsolutions.in/public/auth/",
    live: "https://paladmin.dnsappdemo.com/public/auth/",
    metro: "https://metroadmin.dnsappdemo.com/public/auth/",
}

const image = {
    client: "https://pal.otademo.com/assets/img/vehicles/",
    dev: "https://pal.diginestsolutions.in/assets/img/vehicles/",
    live: "https://paladmin.dnsappdemo.com/assets/img/vehicles/",
    metro: "https://metroadmin.dnsappdemo.com/assets/img/vehicles/",
}

const noshow = {
    client: "https://pal.otademo.com/storage/",
    dev: "https://pal.diginestsolutions.in/storage/",
    live: "https://paladmin.dnsappdemo.com/storage/",
    metro: "https://metroadmin.dnsappdemo.com/storage/",
}

const vehicleType = {
    client: "https://pal.otademo.com/assets/img/",
    dev: "https://pal.diginestsolutions.in/assets/img/",
    live: "https://paladmin.dnsappdemo.com/assets/img/",
    metro: "https://metroadmin.dnsappdemo.com/assets/img/",
}

const key = {
    client:"nGw3tbaew9KgjGiXf3JwVnNEDUS4pmsA",
    live: "nGw3tbaew9KgjGiXf3JwVnNEDUS4pmsA",
    dev: "nGw3tbaew9KgjGiXf3JwVnNEDUS4pmsA",
    metro: "nGw3tbaew9KgjGiXf3JwVnNEDUS4pmsA"
}

//'https://apidev.myfutton.com' //Dev mode= http://apidev.myfutton.com //Live Mode='http://api.myfutton.com';
//Dev mode= http://apidev.myfutton.com/api/v1/student //Live Mode=http://api.myfutton.com/api/v1/student';

export const BASE_URL = URLS[env]
export const IMAGE_URL = image[env]
export const NO_SHOW_IMG = noshow[env]
export const VIEW_URL = viewurl[env]
export const VEHICLETYPE_IMG = vehicleType[env]


export const API_URL = `${url[env]}`
export const API_KEY = `${key[env]}`



