import { Box, Button, Divider, Grid, Typography } from '@mui/material'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import CarImg from '../../assets/images/IconsNew/sedan-car-front.png'
import AgegroupImg from '../../assets/images/IconsNew/age-group.png'
import BagImg from '../../assets/images/IconsNew/luggage.png'
import Vehicle1Img from '../../assets/images/Vehi1.jpg'
import '../Summary/style.scss'
import { add, capitalize, includes, isArray, isEmpty } from 'lodash'
import { BOOKING_INPUT, BOOKING_TYPE, SELECTED_VEHICLE, SET_SELECTED_BOOKING } from '../../Redux/constants/bookingConstants'
import { checkAirportType } from '../../Redux/actions/bookingActions'
import { useNavigate } from 'react-router-dom'
import { VEHICLETYPE_IMG } from '../../config/Constants'
import { calculateExtra } from '../../helper/extraCharges'
import reactotron from '../../ReactronConfig'
import customAxios from '../../CustomAxios'




const Vehicle = ({ item }) => {

    console.log({ item })

    const { bookingData } = useSelector(state => state.booking)
    const { user, additionalStops } = useSelector(state => state.auth)
    const { markupLists } = useSelector(state => state.markup)

    const navigate = useNavigate()
    const dispatch = useDispatch()


    // const calculateExtraCharges = (date) => {
    //     let extras = 0 ;
    //     if(isArray(item?.extraChargesNew)){
    //         item?.extraChargesNew?.map(extra => {
    
    //             let rateType = extra?.rate_type
    //             //text
    //             if(extra?.input_type === "text"){
    //                 if(rateType === "percentage"){
    //                     extras += bookingData?.type === "hour" ? ((parseFloat(rate) / 100) * parseFloat(extra?.rate) * parseFloat(bookingData?.duration)) : (parseFloat(rate) / 100) * parseFloat(extra?.rate)
    //                 }
    //                 else if(rateType === "flatrate"){
    //                     extras += bookingData?.type === "hour" ? (parseFloat(extra?.rate) * parseFloat(bookingData?.duration)) : parseFloat(extra?.rate)
    //                 }
    //                 else if(rateType === "times"){
    //                     extras += bookingData?.type === "hour" ? ((rate * parseFloat(extra?.rate)) * parseFloat(bookingData?.duration)) : rate * parseFloat(extra?.rate)
    //                 }
    //             }
    
    //             //date
    //             if(extra?.input_type === "date"){
    //                 const date1 = moment(extra?.label1, "MM/DD/YYYY");
    //                 let checkTime = moment(date)
    //                 // Check if the time is between the start and end times
    //                 const isBetween = moment(date1).isSame(checkTime, 'day')
    //                 let rateType = extra?.rate_type
    
    //                 //console.log({isBetween}, "date", date1, checkTime)
    
    //                 if(isBetween){
    //                     if(rateType === "percentage"){
    //                         extras += bookingData?.type === "hour" ? ((parseFloat(rate) / 100) * parseFloat(extra?.rate) * parseFloat(bookingData?.duration)) : (parseFloat(rate) / 100) * parseFloat(extra?.rate)
    //                     }
    //                     else if(rateType === "flatrate"){
    //                         extras += bookingData?.type === "hour" ? (parseFloat(extra?.rate) * parseFloat(bookingData?.duration)) : parseFloat(extra?.rate)
    //                     }
    //                     else if(rateType === "times"){
    //                         extras += bookingData?.type === "hour" ? (rate * parseFloat(extra?.rate)) : parseFloat(extra?.rate)
    //                     }
    //                 }
    //             }
    
    
    //             //timerange
    //             if(extra?.input_type === "timerange"){
    //                 const startTime = moment(extra?.label1, "hh:mm A");
    //                 const endTime = moment(extra?.label2, "hh:mm A");
    //                 let checkTime = moment(moment(date).format("HH:mm"), "HH:mm")
    //                 // Check if the time is between the start and end times
    //                 const isBetween = checkTime.isBetween(startTime, endTime);
                    
    
    //                 if(isBetween){
    //                     if(rateType === "percentage"){
    //                         extras += bookingData?.type === "hour" ? ((parseFloat(rate) / 100) * parseFloat(extra?.rate) * parseFloat(bookingData?.duration)) : (parseFloat(rate) / 100) * parseFloat(extra?.rate)
    //                     }
    //                     else if(rateType === "flatrate"){
    //                         extras += bookingData?.type === "hour" ? (parseFloat(extra?.rate) * parseFloat(bookingData?.duration)) : parseFloat(extra?.rate)
    //                     }
    //                     else if(rateType === "times"){
    //                         extras += bookingData?.type === "hour" ? (rate * parseFloat(extra?.rate)) : parseFloat(extra?.rate)
    //                     }
    //                 }
    //             }
    
    
    //             //daterange
    //             if(extra?.input_type === "daterange"){
    //                 let dates = extra?.label1.split('-')
    //                 let date1 = moment(dates[0].trim(), "DD/MM/YYYY");
    //                 let date2 = moment(dates[1].trim(), "DD/MM/YYYY");
    
    //                 const isBetween =moment(date).isBetween(date1, date2, null, '[]');
    
    
    //                //console.log({isBetween})
                    
    
    //                 if(isBetween){
    //                     if(rateType === "percentage"){
    //                         extras += bookingData?.type === "hour" ? ((parseFloat(rate) / 100) * parseFloat(extra?.rate) * parseFloat(bookingData?.duration)) : (parseFloat(rate) / 100) * parseFloat(extra?.rate)
    //                     }
    //                     else if(rateType === "flatrate"){
    //                         extras += bookingData?.type === "hour" ? (parseFloat(extra?.rate) * parseFloat(bookingData?.duration)) : parseFloat(extra?.rate)
    //                     }
    //                     else if(rateType === "times"){
    //                         extras += bookingData?.type === "hour" ? (rate * parseFloat(extra?.rate)) : parseFloat(extra?.rate)
    //                     }
    //                 }
    //             }
    
    
    //             //multidate
    //             if(extra?.input_type === "multidate"){
    //                 let dates = extra?.label1.split(',');
    //                 let isBetween;
    //                 dates?.map(da => {
    //                     //console.log({first: moment(da, "MM/DD/YYYY").format("DD-MM-YYYY"), date: moment(bookingData?.pickupdate).format("DD-MM-YYYY"), da})
    //                     if (moment(date).isSame(moment(da, "MM/DD/YYYY"), 'day')) {
    //                         //console.log("same")
    //                         isBetween = true
    //                     }
    //                 })
    //                 if(isBetween){
    //                     if(rateType === "percentage"){
    //                         extras += bookingData?.type === "hour" ? ((parseFloat(rate) / 100) * parseFloat(extra?.rate) * parseFloat(bookingData?.duration)) : (parseFloat(rate) / 100) * parseFloat(extra?.rate)
    //                     }
    //                     else if(rateType === "flatrate"){
    //                         extras += bookingData?.type === "hour" ? (parseFloat(extra?.rate) * parseFloat(bookingData?.duration)) : parseFloat(extra?.rate)
    //                     }
    //                     else if(rateType === "times"){
    //                         extras += bookingData?.type === "hour" ? (rate * parseFloat(extra?.rate)) : parseFloat(extra?.rate)
    //                     }
    //                 }
    //             }
    //         })
    //     }

    //     return extras;
    // }

    let rate = bookingData?.type === "hour" ? Math.ceil(item?.rate) * Math.ceil(bookingData?.duration) : Math.ceil(item?.rate)


    let agentmarkup = 0;
    let adminMarkup = 0
    let tax = 0;
    let total = 0;
    let additionalStop = 0;
    let adminDiscount = 0;
    let extraSeat = Math.ceil(item?.extraSeatRate)
    
    
    let toll = item?.tollrate ? Math.ceil(item?.tollrate) : 0;

    additionalStop = isArray(bookingData?.stops) ? additionalStops ? Math.ceil(bookingData?.stops?.length * additionalStops) : 0 : 0;

    //let rate = bookingData?.type === "hour" ? (parseFloat(item?.rate) * parseFloat(bookingData?.duration)) + additionalStop : parseFloat(item?.rate) + additionalStop

    console.log(item, bookingData?.pickupdate, bookingData?.type, Math.ceil(bookingData?.duration), Math.ceil(item?.rate), 'price')

    //return false;
    
    let extras = calculateExtra(item, bookingData?.pickupdate, bookingData?.type, Math.ceil(bookingData?.duration), Math.ceil(item?.rate), 'price');

    //console.log({extras, item})
    


    // if (isArray(item?.extraCharges)) {
    //     item?.extraCharges?.map(extra => {
    //         if (extra?.rate_type === "percentage") {
    //             extras += bookingData?.type === "hour" ? ((parseFloat(rate) / 100) * parseFloat(extra?.rate) * parseFloat(bookingData?.duration)) : (parseFloat(rate) / 100) * parseFloat(extra?.rate)
    //         }
    //         else {
    //             extras += bookingData?.type === "hour" ? (parseFloat(extra?.rate) * parseFloat(bookingData?.duration)) : parseFloat(extra?.rate)
    //         }
    //     })
    // }


    if (user?.role === "agent" && markupLists?.type === "fixed") {

        if (markupLists?.status === 1) {
            agentmarkup = Math.ceil(markupLists?.value);
        }

        if (item?.markUpDetails && item?.markUpDetails?.length > 0) {
            if (item?.markUpDetails[0]?.value_type === "Percentage") {
                adminMarkup =  Math.ceil(((rate + extras + toll + additionalStop + extraSeat) / 100) * Math.ceil(item?.markUpDetails[0]?.value))
            }
            else {
                adminMarkup = Math.ceil(item?.markUpDetails[0]?.value)
            }
        }
        else if (item?.defaultMarkup) {
            adminMarkup = Math.ceil(((rate + extras + toll + additionalStop + extraSeat) / 100) * Math.ceil(item?.defaultMarkup?.value))
        }

        if(item?.discountData){
            let ddata = item?.discountData;
            let totalAmount = rate + adminMarkup + agentmarkup + toll + extras + additionalStop + extraSeat;
            if(ddata?.discount_type === "percentage"){
                adminDiscount = Math.ceil((totalAmount/100) * Math.ceil(ddata?.discount))
            }
            else{
                adminDiscount += Math.ceil(ddata?.discount)
            }
        }

        if (bookingData?.bookingType === "return") {
            extras += calculateExtra(item, bookingData?.returndate, bookingData?.type, Math.ceil(bookingData?.duration), Math.ceil(item?.rate), 'price');
            rate = rate * 2;
            adminMarkup = adminMarkup * 2;
            additionalStop = additionalStop * 2;
            //extras = extras * 2;
            adminDiscount = adminDiscount * 2;
            extraSeat = extraSeat *2;

            toll = toll * 2;
        }

        

        tax = Math.ceil(((rate + adminMarkup + agentmarkup + extras + toll - adminDiscount + additionalStop + extraSeat) / 100) * parseFloat(item?.tax?.value))


        total = Math.ceil(rate + adminMarkup + agentmarkup + tax + extras + toll - adminDiscount + additionalStop + extraSeat);
    }
    else {

        if (user?.role === "agent" && markupLists?.status === 1) {
            agentmarkup = Math.ceil(((rate + extras + toll + additionalStop + extraSeat) / 100) * Math.ceil(markupLists?.value))
        }

        if (item?.markUpDetails && item?.markUpDetails?.length > 0) {
            if (item?.markUpDetails[0]?.value_type === "Percentage") {
                adminMarkup = Math.ceil(((rate + extras + toll + additionalStop + extraSeat) / 100) * Math.ceil(item?.markUpDetails[0]?.value))
            }
            else {
                adminMarkup = Math.ceil(item?.markUpDetails?.[0]?.value)
            }
        }
        else if (item?.defaultMarkup) {
            adminMarkup = ((rate + extras + toll + additionalStop + extraSeat) / 100) * Math.ceil(item?.defaultMarkup?.value)
        }

        if(item?.discountData){
            let ddata = item?.discountData;
            let totalAmount = Math.ceil(rate + adminMarkup + agentmarkup + toll + extras + additionalStop + extraSeat);
            if(ddata?.discount_type === "percentage"){
                adminDiscount = Math.ceil((totalAmount/100) * Math.ceil(ddata?.discount))
            }
            else{
                adminDiscount += Math.ceil(ddata?.discount)
            }
        }

        if (bookingData?.bookingType === "return") {
            extras += calculateExtra(item, bookingData?.returndate, bookingData?.type, Math.ceil(bookingData?.duration), Math.ceil(item?.rate), 'price');
            rate = rate * 2;
            adminMarkup = adminMarkup * 2;
            additionalStop = additionalStop * 2;
            adminDiscount = adminDiscount * 2;
            extraSeat = extraSeat * 2;
            toll = toll * 2;
        }

        reactotron.log({rate ,adminMarkup ,agentmarkup ,tax ,extras ,toll ,adminDiscount ,additionalStop ,extraSeat})

        tax = ((rate + adminMarkup + agentmarkup + extras + toll - adminDiscount + additionalStop + extraSeat) / 100) * Math.ceil(item?.tax?.value)
        //reactotron.log({rate, adminMarkup, agentmarkup, extras, toll, tax: item?.tax?.value, adminDiscount})

        total = Math.ceil(rate + adminMarkup + agentmarkup + tax + extras + toll - adminDiscount + additionalStop + extraSeat).toFixed(2);
    }

    const selectCurrentVehicle = async(data) => {

        dispatch({
            type: BOOKING_INPUT,
            payload: {
                prop: 'priceplan',
                value: data
            }
        })

        reactotron.log({item})

        // let discountsData;

        // let disdata = {
        //     customer_type: user?.role === "customer" ? 3 : user?.role === "corporate" ? 1 : user?.role === "agent" ? 2 : 4,
        //     vehicle_type_id: item?.vehicle_type_id,
        //     user_id: user?.id
        // }


        // await customAxios.post('discounts', disdata)
        // .then(res => {
        //     //reactotron.log()
        //     if(res?.data?.data){
        //         discountsData = res?.data?.data
        //     }
            
        // })
        // .catch(err => {

        // })
        // .finally(() => {

        // })

        dispatch({
            type: BOOKING_TYPE,
            payload: 'PAL'
        })

        let agentmarkup = 0;
        let adminMarkup = 0
        let tax = 0;
        let total = 0;
        let additionalStop = 0;
        let baserate = 0;
        let extras = 0, extrasReturn = 0;
        let extraCharges = null, extraChargesReturn = null;
        let toll = data?.tollrate ? parseFloat(data?.tollrate) : 0;
        let extraSeat = Math.ceil(item?.extraSeatRate)
        //let adminDiscount = 0;

        // extraCharges = data?.extraCharges?.map(extra => {
        //     if (extra?.rate_type === "percentage") {
        //         extras += bookingData?.type === "hour" ? ((parseFloat(baserate) / 100) * parseFloat(extra?.rate) * parseFloat(bookingData?.duration)) : (parseFloat(baserate) / 100) * parseFloat(extra?.rate)
        //         return {
        //             id: extra?.addonitem?.id,
        //             name: extra?.addonitem?.name,
        //             price: bookingData?.type === "hour" ? ((parseFloat(baserate) / 100) * parseFloat(extra?.rate) * parseFloat(bookingData?.duration)) : (parseFloat(baserate) / 100) * parseFloat(extra?.rate)
        //         }
        //     }
        //     else {
        //         extras += bookingData?.type === "hour" ? (parseFloat(extra?.rate) * parseFloat(bookingData?.duration)) : parseFloat(extra?.rate)
        //         return {
        //             id: extra?.addonitem?.id,
        //             name: extra?.addonitem?.name,
        //             price: bookingData?.type === "hour" ? (parseFloat(extra?.rate) * parseFloat(bookingData?.duration)) : parseFloat(extra?.rate)
        //         }
        //     }
        // })

        additionalStop = bookingData?.stops ? additionalStops ? bookingData?.stops?.length * additionalStops : 0 : 0;
        baserate = bookingData?.type === "hour" ? (Math.ceil(data?.rate) * Math.ceil(bookingData?.duration)) + additionalStop : parseFloat(data?.rate) + additionalStop


        extras = calculateExtra(data, bookingData?.pickupdate, bookingData?.type, Math.ceil(bookingData?.duration), Math.ceil(data?.rate), 'price'); 
        if (bookingData?.bookingType === "return") {
            extrasReturn = calculateExtra(data, bookingData?.returndate, bookingData?.type, Math.ceil(bookingData?.duration), Math.ceil(data?.rate), 'price'); 
            extraChargesReturn = calculateExtra(data, bookingData?.returndate, bookingData?.type,  Math.ceil(bookingData?.duration), Math.ceil(data?.rate), 'array'); 
        }

        extraCharges = calculateExtra(data, bookingData?.pickupdate, bookingData?.type,  Math.ceil(bookingData?.duration), Math.ceil(data?.rate), 'array'); 

        

        if (user?.role === "agent" && markupLists?.type === "fixed") {




            if (markupLists?.status === 1) {
                agentmarkup = Math.ceil(markupLists?.value);
            }

            if (data?.markUpDetails && data?.markUpDetails?.length > 0) {
                if (data?.markUpDetails[0]?.value_type === "Percentage") {
                    adminMarkup = Math.ceil(((baserate + extras + toll + extraSeat) / 100) * Math.ceil(data?.markUpDetails[0]?.value))
                }
                else {
                    adminMarkup = Math.ceil(data?.markUpDetails[0]?.value)
                }
            }
            else if (data?.defaultMarkup) {
                adminMarkup = Math.ceil((baserate + extras + toll + extraSeat) / 100) * Math.ceil(data?.defaultMarkup?.value)
            }


            

            tax = ((baserate + adminMarkup + agentmarkup + extras + toll + extraSeat) / 100) * Math.ceil(data?.tax?.value)

            total = Math.ceil(baserate + adminMarkup + agentmarkup + extras + tax + toll + extraSeat);
        }
        else {

            if (user?.role === "agent" && markupLists?.status === 1) {
                agentmarkup = Math.ceil(((baserate + extras + toll + extraSeat) / 100) * Math.ceil(markupLists?.value))
            }

            if (data?.markUpDetails && data?.markUpDetails?.length > 0) {
                if (data?.markUpDetails[0]?.value_type === "Percentage") {
                    adminMarkup = Math.ceil(((baserate + extras + toll + extraSeat) / 100) * Math.ceil(data?.markUpDetails[0]?.value))
                }
                else {
                    adminMarkup = Math.ceil(data?.markUpDetails[0]?.value)
                }
            }
            else if (data?.defaultMarkup) {
                adminMarkup = Math.ceil(((baserate + extras + toll + extraSeat) / 100) * Math.ceil(data?.defaultMarkup?.value))
            }

            // if(data?.discountData){
            //     let ddata = data?.discountData;
            //     let totalAmount = baserate + adminMarkup + agentmarkup + toll + extras;
            //     if(ddata?.discount_type === "percentage"){
            //         adminDiscount = (totalAmount/100) * parseFloat(ddata?.discount)
            //     }
            //     else{
            //         adminDiscount += parseFloat(ddata?.discount)
            //     }
            // }

            //reactotron.log({ baserate , adminMarkup, agentmarkup, extras, toll, adminDiscount })

            tax = ((baserate + adminMarkup + agentmarkup + extras + toll + extraSeat) / 100) * Math.ceil(data?.tax?.value)

            total = Math.ceil(baserate + adminMarkup + agentmarkup + extras + tax + toll + extraSeat);
        }

        let datas = {
            ...data,
            price: baserate,
            adminMarkup,
            agentmarkup,
            extraRate: extras,
            extrasReturn,
            total: total,
            taxValue: tax,
            extraSeat,
            addonItem: data?.extraChargesNew,
            toll: toll,
            extraCharges,
            extraChargesReturn,
            discountsData: data?.discountData
        }

        dispatch({
            type: SELECTED_VEHICLE,
            payload: datas
        })

        dispatch({
            type: SET_SELECTED_BOOKING,
            payload: {
                booking: bookingData,
                selectedVehicle: datas,
                id: 'current'
            }
        })

        navigate("/passengerdetails")
    }



    return (
        <Box display={"flex"} flexDirection="row" mt={1}>
            <div style={{ flex: 0.92 }}>
                <Typography className='nameText'>{item?.vehicledetails?.vehicle_type?.vehicle_type}</Typography>
                {/* <Typography className='classText'>{capitalize(item?.vehicledetails?.veh_details?.brand)}</Typography> */}
                {
                    item?.vehicledetails?.description ? <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'start', marginBottom: 0, alignItems: 'center', marginBottom: 5, marginTop: 5 }}>
                        <img src={CarImg} style={{ width: 25, height: 25 }} />
                        <Typography className='descriptionText' style={{ flexWrap: 'wrap', marginLeft: 30 }}>{item?.vehicledetails?.description}</Typography>
                    </div> : <div style={{ width: 30, height: 37 }}>
                        <Typography className='descriptionText' style={{ flexWrap: 'wrap', marginLeft: 30 }}>{item?.vehicledetails?.description}</Typography>
                    </div>
                }
                <Box display={"flex"} flexDirection="row" justifyContent="space-between" >
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'start', marginBottom: 0, flex: 0.5, alignItems: 'center' }}>
                        <img src={AgegroupImg} style={{ width: 25, height: 25 }} />
                        <Typography className='descriptionText' style={{ flexWrap: 'wrap', marginLeft: 30 }}>Max: {item?.vehicledetails?.vehicle_type?.no_of_passenger}</Typography>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'start', flex: 0.5, alignItems: 'center' }}>
                        <img src={BagImg} style={{ width: 25, height: 25 }} />
                        <Typography className='descriptionText' style={{ flexWrap: 'wrap', marginLeft: 30 }}>Max: {item?.vehicledetails?.vehicle_type?.no_check_in}</Typography>
                    </div>
                </Box>
                <Box display={"flex"} flexDirection="row" justifyContent="space-between" alignItems={"center"} flex={1} mt={3}>
                    <Typography className='pricetext' flex={0.8}>S${Math.ceil(total)}</Typography>
                    <div style={{ flex: 0.5 }}>
                        <Button

                            variant="contained"
                            style={{
                                borderRadius: 1,
                                backgroundColor: '#333333',
                                height: 30,
                                borderRadius: 4,
                                padding: 5
                            }}
                            onClick={() => selectCurrentVehicle(item)}
                            className='buttonText'
                        >
                            Select
                        </Button>
                    </div>

                </Box>
                {/* <Grid xs={12} md={4}>
                    {item?.vehicledetails?.vehicle_images?.length > 0 ? <img src={`${item.imageBasePath}${item?.vehicledetails?.vehicle_images[0].path}`} style={{ width: 300, height: '100%' }} resizeMode={'contain'} /> : <img src={Vehicle1Img} style={{ width: 300, height: '100%' }} resizeMode={'contain'} />}
                </Grid> */}
                <Grid xs={12} md={4}>
                    {item?.vehicledetails?.vehicle_type?.image ? <img src={`${VEHICLETYPE_IMG}${item?.vehicledetails?.vehicle_type?.image }`} style={{ width: 300, height: '100%' }} resizeMode={'contain'} /> :  item?.vehicledetails?.vehicle_images?.length > 0 ? <img src={`${item.imageBasePath}${item?.vehicledetails?.vehicle_images[0].path}`} style={{ width: 300, height: '100%' }} resizeMode={'contain'} /> : <img src={Vehicle1Img} style={{ width: 300, height: '100%' }} resizeMode={'contain'} />}
                </Grid>
            </div>
            <Divider flexItem orientation='vertical' />
        </Box>
    )
}

export default Vehicle