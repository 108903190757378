import React, { useState, useEffect } from 'react'
import { Grid, Typography, Button, Snackbar, Alert, InputLabel, IconButton, FormControl, CircularProgress, Stack, Box } from '@mui/material'
import InputField from '../../components/InputField'
import { Link, useNavigate, useLocation, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { useDispatch, useSelector } from 'react-redux'
import customAxios from '../../CustomAxios';
import { LOADING } from '../../Redux/constants/userConstants';
import { toast } from 'react-toastify';

const ResetPassword = ({ match }) => {

	const dispatch = useDispatch();
	const [open, setOpen] = React.useState(false);
	const { token } = useParams();



	const handleClose = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}
		setOpen(false);
		//navigate("/signin")
	};




	const verifyToken = async (data) => {
		dispatch({
			type: LOADING,
			payload: true
		})
		let datas = {
			token,
			password: data?.password
		}
		await customAxios.post(`auth/tokencheck`, datas)
			.then(async response => {
				dispatch({
					type: LOADING,
					payload: false
				})
				setOpen(true)
				navigate("/signin")
			})
			.catch(async error => {
				toast.error(error)

				dispatch({
					type: LOADING,
					payload: false
				})

			});
	}


	const schema = yup.object({
		password: yup.string()
			.required('No password provided.')
			.min(8,'Password is too short - should be 8 chars minimum.').max(30, 'Maximun Character exceeded'),
		confirmpassword: yup.string().oneOf([yup.ref('password'), null], 'Passwords must match')
	}).required();

	const navigate = useNavigate();
	const { register, handleSubmit, control, formState: { errors } } = useForm({
		resolver: yupResolver(schema),
		mode:'onChange'
	});
	const { loading, isAuthenticated, error } = useSelector(state => state.auth)


	const onSubmit = data => {
		setOpen(true)
	};






	return (
		<div>
			<Grid container>
				<Grid xs={12} md={7} sx={{ flexDirection: 'row', justifyContent: 'start', alignItems: 'center' }} display={{ xs: "none", md: "block" }} className=" loginBackground" >

				</Grid>

				<Grid xs={12} md={5} className=" forgot" >
                    <Snackbar open={open} autoHideDuration={3000} onClose={handleClose}>
                        <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
                            You are successfully changed your password. You can login with your new password.
                        </Alert>
                    </Snackbar>
					{token ? <form onSubmit={handleSubmit(verifyToken)}>
						<div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'start', alignItems: 'center' }}>
							<h1 className='loginheading' style={{ paddingBottom: '2%' }}>RESET PASSWORD</h1>

							<InputField
								fieldLabel="New Password"
								fieldName="password"
								control={control}
								error={errors.password}
								width={{ xs: "95%", md: '50%' }}
								fieldType={"password"}
								placeholder='Password needs to have at least 8 characters...'
							/>
							<InputField
								fieldLabel="Confirm Password"
								fieldName="confirmpassword"
								control={control}
								error={errors.confirmpassword}
								width={{ xs: "95%", md: '50%' }}
								fieldType={"password"}
							/>

						</div>


						<div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', paddingTop: '2%' }}>
							{loading ? <CircularProgress /> : <Button type="submit"
								sx={{ boxShadow: 2 }}
								variant="filled" style={{ backgroundColor: '#333333', borderRadius: 15, height: 35, width: 130, }}
							>
								<Typography className='loginButton'>SAVE</Typography>
							</Button>}

						</div>

					</form> : <h1 className='loginheading' style={{ paddingBottom: '2%' }}>INVALID TOKEN</h1>}
				</Grid>
			</Grid>
		</div>
	)
}

export default ResetPassword
