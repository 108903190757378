import reactotron from "../ReactronConfig";
import { calculateExtra } from "./extraCharges";
import { isArray } from 'lodash'

export const pricePlan = (item, bookingData, additionalStops, user, markupLists, service) => {

    reactotron.log({bookingDatas: bookingData})

    let agentmarkup = 0;
    let adminMarkup = 0
    let tax = 0;
    let total = 0;
    let additionalStop = 0;
    let adminDiscount = 0;
    let services = item?.services?.map(ser => ser?.id)
    let serviceCharge = 0;
    let extraSeat = item?.extraSeatRate ?  Math.ceil(item?.extraSeatRate) : 0
    serviceCharge = item?.services?.length > 0 ? item?.services?.reduce(function (acc, obj) { return acc + obj.price; }, 0) : 0;


    let newAddOns = item?.addOnServices?.map(addon => {
        return {
            id: addon?.id,
            name: addon?.addonservice?.name,
            price: addon?.rate,
            checked: services?.includes(addon?.id) ? true : false
        }
    })

    let toll = item?.tollrate ? Math.ceil(item?.tollrate) : 0;

    additionalStop = isArray(bookingData?.stops) ? additionalStops ? bookingData?.stops?.length * additionalStops : bookingData?.stops?.length * 20 : 0;

    let rate = bookingData?.type === "hour" ? Math.ceil((parseFloat(item?.rate) * parseFloat(bookingData?.duration)) + additionalStop) : Math.ceil(item?.rate) + additionalStop

    
    let extras = calculateExtra(item, bookingData?.pickupdate, bookingData?.type, bookingData?.duration, rate, 'price');
    let extraArray = calculateExtra(item, bookingData?.pickupdate, bookingData?.type, bookingData?.duration, rate, 'array');

    if (user?.role === "agent" && markupLists?.type === "fixed") {

        if (markupLists?.status === 1) {
            agentmarkup = Math.ceil(markupLists?.value);
        }

        if (item?.markUpDetails && item?.markUpDetails?.length > 0) {
            if (item?.markUpDetails[0]?.value_type === "Percentage") {
                
                adminMarkup = Math.ceil(((rate + extras + toll + extraSeat) / 100) * parseFloat(item?.markUpDetails[0]?.value))
            }
            else {
                adminMarkup = Math.ceil(item?.markUpDetails[0]?.value)
            }
        }
        else if (item?.defaultMarkup) {
            adminMarkup = Math.ceil(((rate + extras + toll + extraSeat) / 100) * parseFloat(item?.defaultMarkup?.value))
        }

        if(item?.discountData){
            let ddata = item?.discountData;
            let totalAmount = rate + adminMarkup + agentmarkup + toll + extras + extraSeat;
            if(ddata?.discount_type === "percentage"){
                adminDiscount = Math.ceil((totalAmount/100) * parseFloat(ddata?.discount))
            }
            else{
                adminDiscount += Math.ceil(ddata?.discount)
            }
        }

        if (bookingData?.bookingType === "return") {
            extras += calculateExtra(item, bookingData?.returndate, bookingData?.type, bookingData?.duration, rate, 'price');
            rate = rate * 2;
            adminMarkup = adminMarkup * 2;
            serviceCharge = serviceCharge * 2;
            extraSeat = extraSeat * 2;
            adminDiscount = adminDiscount * 2;

            toll = toll * 2;
        }

        

        tax = Math.ceil(((rate + adminMarkup + agentmarkup + extras + toll - adminDiscount + serviceCharge + extraSeat) / 100) * parseFloat(item?.tax?.value))

        total = Math.ceil(rate + adminMarkup + agentmarkup + tax + extras + toll - adminDiscount + serviceCharge + extraSeat);

        return {
            id: item?.id,
            baseRate: rate,
            adminMarkup,
            agentmarkup,
            extras,
            extraArray,
            toll,
            adminDiscount,
            serviceCharge,
            tax,
            total: total,
            vehicleType: item?.vehicledetails?.vehicle_type?.vehicle_type,
            newAddOns,
            vehicleTypeId: item?.vehicledetails?.vehicle_type?.id,
            vehicleId: item?.vehicledetails?.veh_details?.vehicle_id,
            driverId: item?.vehicledetails?.veh_details?.driver_id,
            extraSeat
        }
    }
    else {

        if (user?.role === "agent" && markupLists?.status === 1) {
            agentmarkup = Math.ceil(((rate + extras + toll + extraSeat) / 100) * parseFloat(markupLists?.value))
        }

        if (item?.markUpDetails && item?.markUpDetails?.length > 0) {
            if (item?.markUpDetails[0]?.value_type === "Percentage") {
                
                adminMarkup = Math.ceil(((rate + extras + toll + extraSeat) / 100) * parseFloat(item?.markUpDetails[0]?.value))
            }
            else {
                
                adminMarkup = Math.ceil(item?.markUpDetails?.[0]?.value)
            }
        }
        else if (item?.defaultMarkup) {
            reactotron.log({rate, extras, toll, extraSeat, markup:item?.defaultMarkup?.value })
            adminMarkup = Math.ceil(((rate + extras + toll + extraSeat) / 100) * parseFloat(item?.defaultMarkup?.value))
        }

        if(item?.discountData){
            let ddata = item?.discountData;
            let totalAmount = rate + adminMarkup + agentmarkup + toll + extras + extraSeat;
            if(ddata?.discount_type === "percentage"){
                adminDiscount = Math.ceil((totalAmount/100) * parseFloat(ddata?.discount))
            }
            else{
                adminDiscount += Math.ceil(ddata?.discount)
            }
        }

        if (bookingData?.bookingType === "return") {
            extras += calculateExtra(item, bookingData?.returndate, bookingData?.type, bookingData?.duration, rate, 'price');
            rate = rate * 2;
            adminMarkup = adminMarkup * 2;
            adminDiscount = adminDiscount * 2;
            serviceCharge = serviceCharge * 2;
            extraSeat = extraSeat * 2;
            toll = toll * 2;
        }

        

        tax = Math.ceil(((rate + adminMarkup + agentmarkup + extras + toll - adminDiscount + serviceCharge + extraSeat) / 100) * parseFloat(item?.tax?.value))

        total = Math.ceil(rate + adminMarkup + agentmarkup + tax + extras + toll - adminDiscount + serviceCharge + extraSeat);


        return {
            id: item?.id,
            baseRate: rate,
            adminMarkup,
            agentmarkup,
            extras,
            extraArray,
            toll,
            adminDiscount,
            tax,
            total: total,
            vehicleType: item?.vehicledetails?.vehicle_type?.vehicle_type,
            newAddOns,
            vehicleTypeId: item?.vehicledetails?.vehicle_type?.id,
            vehicleId: item?.vehicledetails?.veh_details?.vehicle_id,
            driverId: item?.vehicledetails?.veh_details?.driver_id,
            extraSeat
        }
    }



}

